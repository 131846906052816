.s-social__link {
  display: inline-block;
  background-image: url(img/sprite.png);
  width: 40px;
  height: 40px;
  margin: 5px;
  cursor: pointer;
  font-size: 0;
  outline: 0;
  opacity: 0.8;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }

  &--fb {
    @include sprite-position($fb);
  }
  &--gp {
    @include sprite-position($gp);
  }
  &--tw {
    @include sprite-position($tw);
  }
  &--in {
    @include sprite-position($in);
  }
  &--pi {
    @include sprite-position($pi);
  }
  &--yt {
    @include sprite-position($yt);
  }
}

