#views-exposed-form-search-page {
  background: white;
  border: 1px solid $color-border;
  border-radius: 3px;

  .views-exposed-widgets {
    font-size: 0;
    padding: 2px 0 3px;
  }
  .views-exposed-widget {

    float: none;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;


    &.views-widget-filter-combine {
      width: calc(100% - 34px);
      border-right: 1px solid $color-border;
    }


  }
  label {
    display: none;
  }

  input[type=text] {
    border: 0;
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    margin: 0;
    padding: 0 10px;

    background: transparent;
    width: auto;

  }

  .views-submit-button {
    display: inline-block;
    input {
      margin: 2px;
      padding: 0;
      font-size: 0;
      background-color: transparent;
      background-image: url(img/sprite.png);
      @include sprite-position($search);
      width: 20px;
      height: 20px;
      outline: 0;
      border: 1px solid transparent;
    }
  }
}