/**
 * @file
 * Styles are organized using the SMACSS technique. @see http://smacss.com/book/
 *
 * When you turn on CSS aggregation at admin/config/development/performance, all
 * of these @include files will be combined into a single file.
 */

/* HTML element (SMACSS base) rules */
@import "normalize.css";

/* Layout rules */
@import "responsive.css";

/* Component (SMACSS module) rules */
@import "misc.css";
/* Optionally, add your own components here. */
@import "print.css";

/* SMACSS theme rules */
/* @import "theme-A.css"; */
/* @import "theme-B.css"; */

.pointer{
  position:absolute;
  top:200px;
  height:1px;
}
a{
  color:#4d94c0;
  text-decoration:none;
}
a:hover{

}

#block-commerce-cart-cart .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-list .price-amount,
#block-commerce-cart-cart .commerce-price-savings-formatter-prices-down .commerce-price-savings-formatter-list .price-amount{
  display: none;
}
#block-commerce-cart-cart .commerce-price-savings-formatter-prices-down .commerce-price-savings-formatter-price .price-amount{
  color: #000;
}
p{
  margin:0 0 20px 0;
}
.preheader{
  position:fixed;
  top:0;
  z-index:65;
  width: 100%;
}
.admin-menu .preheader{
  top:30px;
}
.front .preheader{
  background:url(../images/headerbgfront.png) repeat-x 0px 0px;
  height:163px;
}
.front.nottop .preheader,
.not-front .preheader{
  background:url(../images/headerbg.png) repeat-x 0px 0px;
  height:127px;
}
.header__logo{

}
#header{
  position:relative;
}
#header ul{
  margin:0;
  padding:0;
}
#header ul li{
  list-style:none;
}

#block-menu-block-3 {
  position: absolute;
  top: 40px;
  left: 270px;
}
.logged-in #block-menu-block-3{
  left: 270px;
}
#block-menu-block-3 li {
  display:inline-block;
  background:url(../images/menuheaderlifront.png) no-repeat 100% 0px;
}
#block-menu-block-3 li {
  background:url(../images/menuheaderli.png) no-repeat 100% 0px;
}
#block-menu-block-3 li.last {
  background:none;
}
#block-menu-block-3 li a {
  font-family:calibri;
  font-size:16px;
  color:#76b3dc;
  text-decoration:none;
  line-height: 30px;
  padding: 5px 10px 5px 3px;
}
.not-front #block-menu-block-3 li a,
.nottop #block-menu-block-3 li a {
  color: #c384b8;
}
#block-block-5{
  position: absolute;
  top: 40px;
  right: 8px;
  font-family:calibri;
  font-weight:700;
  font-size:16px;
  line-height: 30px;
  color: #76b3dc;
  margin: 0;
  background:url(../images/cart.png) no-repeat 0px 1px;
  padding-left: 53px;
}
.not-front #block-block-5,
.nottop #block-block-5{
  background:url(../images/cart.png) no-repeat 0px 1px;
  color: #4d95c0;
}
#block-block-5 .shopping-cart-items-count{
  background:url(../images/menuheaderli.png) no-repeat 100% 0px;
  display:inline-block;
  padding-right: 8px;
}
.cart-empty-block{
  padding:10px;
}
#block-commerce-cart-cart{
  position: absolute;
  top: 40px;
  right: 8px;
  width:180px;
  height:30px;
  text-indent:-9999px;
  margin:0;
}
#block-commerce-cart-cart .block-title:after{
  display:none;
}
div .collapsiblockCollapsed a,
div .collapsiblock a{
  background:none;
  outline:none;
}
#block-commerce-cart-cart .block-content{
  position: absolute;
  top: 49px;
  right: 0px;
  width: 340px;
  padding:0 10px;
  text-align: left;
  text-indent: 0px;
  border:2px solid #4d94c0;
  box-shadow:0px 0px 10px #ccc;
  background: #fff;
  z-index: 500;
}
#block-commerce-cart-cart .block-content .views-field-field-product-image{
  width:80px;
  padding-bottom: 20px;
  padding-top: 10px;
}
#block-commerce-cart-cart .block-content .views-field-quantity{
  font-size:14px;
  color:#333333;
  line-height:16px;
  font-weight:700;
  padding: 10px 10px 0;
  text-align:right;
  position: absolute;
  left: 205px;
  margin-top: 65px;
}
#block-commerce-cart-cart .views-field-edit-delete{
  position: relative;
  right: -7px;
  top: 10px;
}

#block-flexnav-flexnav-1 {display:none;}
#block-commerce-cart-cart .block-content .views-field-line-item-title{
  font-weight:400;
  font-family:calibri;
  color:#666666;
  font-size:14px;
  line-height:16px;
  text-decoration: none;
  padding: 10px 0 0 10px;
  max-height: 50px;
  overflow: hidden;
  display: block;
}
#block-commerce-cart-cart .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-price,
#block-commerce-cart-cart .commerce-price-savings-formatter-price{
  color:#333;
  font-weight:700;
  display: block;
  position: absolute;
  right: -2px;
  top: 74px;
  text-align: right;
}
table.commerce-price-savings-formatter-prices {
  position: relative;
}
#block-commerce-cart-cart .commerce-price-savings-formatter-price{
  right: 243px;
}
#block-commerce-cart-cart .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-list{
  color:#999999;
  font-weight:400;
  display: block;
  position: absolute;
  left: -190px;
  top: 74px;
}
#block-commerce-cart-cart .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-price .price-amount,
#block-commerce-cart-cart .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-list .price-amount{
  font-size:16px;
}
#block-commerce-cart-cart .block-content:before{
  content:" ";
  background:url(../images/carttriangle.png) no-repeat 0px 0px;
  width: 28px;
  height: 25px;
  display: block;
  position: absolute;
  z-index: 16;
  top: -20px;
  right: 130px;
}
#block-commerce-cart-cart table{
  margin:0;
}
#block-commerce-cart-cart table.views-table>tbody>tr.even,
#block-commerce-cart-cart table.views-table>tbody>tr.odd {
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  padding: 0;
}
#block-commerce-cart-cart .view-footer{
  margin-top:15px;
}
.line-item-summary .line-item-total-raw{
  font-weight:700;
}
.line-item-total{
  margin-bottom:15px;
}
#block-commerce-cart-cart .links.inline{
  height: 32px;
  display: block;
}
#block-commerce-cart-cart .links.inline li{
  display:block;
  padding:0;
}
#block-commerce-cart-cart .links.inline li.line-item-summary-view-cart{
  width:155px;
  height:30px;
  text-align:center;
  background:#dddddd;
  border:1px solid #dddddd;
  float: left;
}
#block-commerce-cart-cart .links.inline li.line-item-summary-view-cart:hover{
  background:#eee;
  border:1px solid #999999;
}
#block-commerce-cart-cart .links.inline li.line-item-summary-view-cart a{
  font-size:16px;
  color:#333333;
  line-height:30px;
  text-decoration:none;
  display: block;
  width: 155px;
  height: 30px;
}
#block-commerce-cart-cart .links.inline li.line-item-summary-checkout{
  width:155px;
  height:30px;
  text-align:center;
  background:#4d94c0;
  border:1px solid #4d94c0;
  float: right;
}
#block-commerce-cart-cart .links.inline li.line-item-summary-checkout:hover{
  background:#b3699d;
  border:1px solid #b3699d;
}
#block-commerce-cart-cart .links.inline li.line-item-summary-checkout a{
  font-size:16px;
  color:#fff;
  line-height:30px;
  text-decoration:none;
  display: block;
  width: 155px;
  height: 30px;
}
#block-commerce-cart-cart td.views-field.views-field-commerce-shipping-price {
  position: relative;
}
#block-commerce-cart-cart td.views-field.views-field-commerce-shipping-price .shippingpricedropdowncart{
  position: absolute;
  top: 90px;
  text-align: right;
  right: 0;
  width: 200px;
  font-weight: 700;
}

#block-superfish-1 .block-content{

}
#block-superfish-1 #superfish-1 > li.sf-depth-1{
  width:initial!important;
}
#block-superfish-1 li.sf-depth-1 li.sf-depth-1{
  margin: 0;
}
#block-superfish-1 li a{
  text-decoration:none;
}
#block-superfish-1 li.active-trail a{
  font-weight:700;
}
.not-front #block-superfish-1 li a,
.nottop #block-superfish-1 li a{
  color:#333333;
  text-decoration:none;
}
#block-superfish-1 ul ul{
  border:1px solid #8e8e8e;
  box-shadow:0px 0px 10px #8e8e8e;
  text-align:left;
  background:#fff;
  margin-top:-15px;
  padding:45px 35px;
}

#block-superfish-1 ul li li.sf-depth-1:before{
  display:none;
}
#block-superfish-1 ul #menu-1536-1 ul:before{
  left: initial;
  right:20px;
}
#block-superfish-1 ul #menu-1485-1 ul:before{
  left: 260px;
}
#block-superfish-1 ul #menu-1522-1 ul:before{
  left: 160px;
}
#block-superfish-1 ul #menu-1512-1 ul:before{
  left: 30px;
}
#block-superfish-1 ul #menu-1542-1 ul:before{
  left: 10px;
}
#block-superfish-1 #menu-1445-1 ul{
  width:220px;
}
#block-superfish-1 #menu-1459-1 ul{
  width:950px;
  z-index: 65;
}
#block-superfish-1 #menu-1485-1 ul{
  width:970px;
  margin-left: -245px;
}
#block-superfish-1 #menu-1517-1 ul{
  width:255px;
}
#block-superfish-1 #menu-1522-1 ul{
  width:460px;
  margin-left: -145px;
}
#block-superfish-1 #menu-1512-1 ul{
  width:555px;
}
#block-superfish-1 #menu-1536-1 ul{
  width:775px;
}
#block-superfish-1 #menu-1542-1 ul{
  width: 220px;
}
.sf-menu ul{
  width:500px;
}
#block-superfish-1 li.sf-depth-2{
  display:inline-block;
  vertical-align:top;
  width:100%;
  max-width: 100px;
  margin-right:10px;
}
#block-superfish-1 #menu-1459-1 li.sf-depth-2{
  max-width: 454px;
  text-align:center;
  border-right:1px solid #ccc;
  margin-right:20px;
}
#block-superfish-1 #menu-1459-1 li.sf-depth-2.last{
  border-right:none;
  margin-right:10px;
}
#block-superfish-1 #menu-1459-1 li.sf-depth-3{
  text-align:left;
}
#block-superfish-1 #menu-1485-1 li.sf-depth-2{
  max-width: 150px;
}
#block-superfish-1 #menu-1517-1 li.sf-depth-2{
  max-width: 110px;
}
#block-superfish-1 #menu-1522-1 li.sf-depth-2{
  max-width: 140px;
}
#block-superfish-1 #menu-1536-1 li.sf-depth-2{
  max-width: 145px;
}
#block-superfish-1 ul ul ol a,
.not-front #block-superfish-1 ul ul ol a,
.nottop #block-superfish-1 ul ul ol a{
  color:#666666;
  font-weight:700;
  text-decoration:none;
  margin-bottom:20px;
  outline:none;
}
#block-superfish-1 ul ul ol ol a,
.not-front #block-superfish-1 ul ul ol ol a,
.nottop #block-superfish-1 ul ul ol ol a{
  font-size:14px;
  line-height: 18px;
  color:#336699;
  font-weight:400;
  text-decoration:none;
  padding-left:10px;
  margin-bottom:0px;
  font-family:Myriad pro, Myriad;
  background:url(../images/menulili.png) no-repeat 0px 3px;
  padding-bottom:2px;
}
#block-superfish-1 ul ul ol ol a.active,
.not-front #block-superfish-1 ul ul ol ol a.active,
.nottop #block-superfish-1 ul ul ol ol a.active{
  font-weight:700;
}
#block-superfish-1 #menu-1459-1 li.sf-depth-3{
  display:inline-block;
  width:33%;
}

.front #content{
  margin-top: 15px;
}
.page-search h1 {
  block-title:after
}

.node-type-product-display .block-title{
  font-size:30px;
  line-height: 48px;
  color:#666;
}
.node-type-product-display .block-title:after{
  background:#666;
  height:4px;
}
.view-productslistwithoutfilter .views-row,
.view-search .views-row,
.view-faceted-products .views-row{
  display: inline-block;
  vertical-align: top;
  width: 260px;
  height:300px;
  margin: 0 27px 48px 0%;
  position:relative;
  font-size: 0;
}
.not-front .view-productslistwithoutfilter .views-row{
  display:none;
}
.not-front .view-productslistwithoutfilter .views-row-1,
.not-front .view-productslistwithoutfilter .views-row-2,
.not-front .view-productslistwithoutfilter .views-row-3,
.not-front .view-productslistwithoutfilter .views-row-4{
  display:inline-block;
}
.view-productslistwithoutfilter .views-row:nth-of-type(4n),
.view-search .views-row:nth-of-type(4n) {
  margin-right: 0;
}
.view-faceted-products .views-row:nth-child(3n) {
  margin-right: 0;
}
.view-productslistwithoutfilter .views-row .views-field-title,
.view-search .views-row .views-field-title,
.view-faceted-products .views-row .views-field-title{
  line-height:20px;
  max-height:45px;
  overflow:hidden;
  margin-top:10px;
  margin-bottom:8px;
}
.view-productslistwithoutfilter .views-row .views-field-title a,
.view-search .views-row .views-field-title a,
.view-faceted-products .views-row .views-field-title a{
  font-family:Calibri;
  font-size:18px;
  color:#333333;
  text-decoration:none;
}
.view-productslistwithoutfilter .views-field-commerce-price,
.view-search .views-field-commerce-price,
.view-faceted-products .views-field-commerce-price{
  margin-top:25px;
}
table.commerce-price-savings-formatter-prices tr,
table.commerce-price-savings-formatter-prices td,
table.commerce-price-savings-formatter-prices table,
table.commerce-price-savings-formatter-prices tbody{
  padding:0;
  margin:0;
  width: 100%;
  text-align: left;
}

table.commerce-price-savings-formatter-prices{
  position:relative;
  font-size:18px;
  font-family:Calibri;
  margin-top:20px;
}
.commerce-price-savings-formatter-prices-inline .price-label {
  font-size: 20px;
}
.commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-list{
  position: absolute;
  top: -23px;
  left: 0px;
}
.commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-list .price-amount{
  color:#999999;
  font-size:18px;
  line-height:20px;
}
.commerce-price-savings-formatter-list .price-amount{
  background:url(../images/priceold.png) no-repeat 0px 4px;
  text-decoration:none;
}
.commerce-price-savings-formatter-label-row .commerce-price-savings-formatter-list{
  display:none;
}
.commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-price{

}
.commerce-price-savings-formatter-label-row .commerce-price-savings-formatter-price{
  display:none;
}
.commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-savings{
  position: absolute;
  top: -3px;
  left: 100px;
}
.commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-savings .price-amount{
  color:#508ec7;
  font-size:18px;
  line-height:20px;
  font-weight:700;
}
.commerce-price-savings-formatter-label-row .commerce-price-savings-formatter-savings{
  position: absolute;
  top: -1px;
  left: 70px;
  width: 51px;
}
#views_slideshow_cycle_teaser_section_productpage-block{
  margin: 0 auto;
}
.commerce-price-savings-formatter-label-row .commerce-price-savings-formatter-savings .price-label{
  font-size:14px;
  line-height:16px;
  color:#508ec7;
}
.commerce-price-savings-formatter-prices-inline tr.commerce-price-savings-formatter-label-row td {
  line-height: 16px;
}

.views-field-add-to-cart-form input[type="submit"]{
  font-family:Calibri;
  font-size:18px;
  color:#fff;
  text-transform:lowercase;
  border:none;
  background:#508ec7;
  padding:0 10px;
  font-weight:400;
  height:21px;
  line-height:21px;
}

.views-field-add-to-cart-form input[type="submit"]:hover{
  background:#b3699d;
}
.productlabeltype-0{
  display:none;
}
.productlabeltype-1{
  position:absolute;
  top:0px;
  right:0px;
  width:53px;
  height:21px;
  background:url(../images/labelnew.png) no-repeat 0px 0px;
}
.productlabeltype-2{
  position:absolute;
  top:0px;
  right:0px;
  width:53px;
  height:21px;
  background:url(../images/labelsale.png) no-repeat 0px 0px;
}
.front .prefooter2{
  margin-top:12px;
}
.prefooter2{
  background:#eeeeee;
}
.region-footer2{
  padding:75px 0 95px 0;
  height:260px;
}
.view-deals .views-row{
  display:inline-block;
  vertical-align:top;
}
.view-deals .views-row.views-row-1{
  margin-right: 45px;
}
.view-deals .views-row.views-row-3{
  float:right;
}
.prefooter{
  background:#666666;
  padding-top:80px;
  height:180px;
}
#footer{
  color:#cccccc;
  font-size:16px;
  font-family:Trebuchet MS;
  padding:0 10px;
  position:relative;
}
#footer p{
  margin:0;
  padding:0;
}
#footer a{
  color:#cccccc;
  text-decoration:none;
}
#footer #block-block-1{
  font-size:14px;
}
#footer #block-block-2{
  font-size:12px;
}
#footer #block-block-3{
  position: absolute;
  right: 18px;
  top: 0px;
}
#footer #block-menu-block-1{
  position:absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top:0;
}
#footer #block-menu-block-1 ul{
  margin:0;
  padding:0;
}
#footer #block-menu-block-1 li{
  display:inline-block;
  list-style:none;
  padding:0 24px;
}
#footer #block-menu-block-1 li a{
  text-decoration:none;
  color:#fff;
  font-size:22px;
  font-family:Calibri;
}


/* start PAGE */
.not-front #page{
  margin-top:177px;
  margin-bottom:110px;
}
.delimiter{
  background:url(../images/breadcrumbli.png) no-repeat 0 7px;
  text-indent: -9999px;
  display: inline-block;
  width: 4px;
  height: 16px;
  margin: 0 4px;
}
.breadcrumb{
  color:#4d95c0;
  font-size:12px;
  float: left;
  margin-right: 5px;
}
.breadcrumb a{
  color:#4d95c0;
  text-decoration:none;
}
.breadcrumb .last {
  color: #999;
}
.afterbread{
  color:#999999;
  font-size:12px;
}
.field-name-body{
  font-family:Verdana;
  font-size:16px;
  line-height:20px;
}
.field-name-body img{
  margin-right:55px;
}
.not-front article{
  margin-top:35px;
}
/* end PAGE */

/* start CART */
.view-commerce-cart-form{
  margin-top:35px;
}
.view-commerce-cart-form table{
  width:100%;
  margin:0;
}
form th{
  border:none;
  font-weight:700;
  font-size:14px;
  color:#666666;
  padding-bottom:0px;
  padding-right: 0;
  text-align: center;
}
form tbody {
  border:none;
}
.view-commerce-cart-form tr.even,
.view-commerce-cart-form tr.odd {
  background: none;
  border-bottom: 1px solid #dddddd;
  padding: 0;
}
.view-commerce-cart-form tr tr.even,
.view-commerce-cart-form tr tr.odd {
  border-bottom: none;
}
.view-commerce-cart-form td{
  padding-top:20px;
}
.view-commerce-cart-form td.views-field-field-product-image img{
  margin-bottom:20px;
  min-width: 110px;
}
.view-commerce-cart-form td.views-field-field-product-image{
  padding-right:35px;
}
.view-commerce-cart-form td.views-field-line-item-title{
  font-size:18px;
  color:#333333;
  font-weight:700;
}
.view-commerce-cart-form td.views-field-line-item-title .itemtitle{
  margin-bottom:10px;
}
.view-commerce-cart-form td.views-field-line-item-title .deliverytime,
.deliverytime,
.view-commerce-cart-form td.views-field-line-item-title .productiontime,
.productiontime{
  font-size:16px;
  font-weight:400;
  color:#666;
  line-height:30px;
  background:url(../images/deliverytime.png) no-repeat 0px 6px;
  padding-left:32px;
}
.view-commerce-cart-form th.views-field-commerce-unit-price,
.view-commerce-cart-form th.views-field-commerce-total,
.view-commerce-cart-form th.views-field-commerce-shipping-price{
  text-align:center;
}
.view-commerce-cart-form td.views-field-commerce-unit-price,
.view-commerce-cart-form td.views-field-commerce-total,
.view-commerce-cart-form td.views-field-commerce-shipping-price{
  min-width:100px;
  text-align: center;
  padding-left:30px;
}
.view-commerce-cart-form td.views-field-commerce-unit-price .commerce-price-savings-formatter-prices tr td,
.view-commerce-cart-form td.views-field-commerce-total .commerce-price-savings-formatter-prices tr td{
  text-align:left;
}
.view-commerce-cart-form td.views-field-commerce-unit-price .commerce-price-savings-formatter-price .price-amount,
.view-commerce-cart-form td.views-field-commerce-total .commerce-price-savings-formatter-price .price-amount,
.view-commerce-cart-form td.views-field-commerce-shipping-price{
  font-size:22px;
  color:#000000;
  font-weight:700;
}
.view-commerce-cart-form td.views-field-commerce-unit-price .commerce-price-savings-formatter-list,
.view-commerce-cart-form td.views-field-commerce-total .commerce-price-savings-formatter-list{
  font-weight:400;
  font-size:18px;
  top:20px;
}
.form-item-quantity input.form-text,
.views-field-edit-quantity input.form-text{
  width:29px;
  height:29px;
  border:1px solid #999999;
  outline:none;
  text-align: center;
  font-size: 18px;
  color: #333;
  padding:0;
  -webkit-border-radius: 0 ;
  -moz-border-radius: 0;
  border-radius: 0;

}
.form-textarea-wrapper textarea{
  border: 2px solid rgb(169, 169, 169);
}
.view-commerce-cart-form .views-field-edit-quantity{
  text-align:center;
}
.view-commerce-cart-form .views-field-edit-quantity{
  min-width: 92px;
  text-align:center;
}
.commerce-quantity-plusminus-link.commerce-quantity-plusminus-link-decrease a.button,
.commerce-quantity-plusminus-link.commerce-quantity-plusminus-link-increase a.button{
  background:url(../images/minus.png) no-repeat 0px 0px;
  display:inline-block;
  vertical-align: top;
  text-indent:-9999px;
  margin:0;
  padding:0;
  border:none;
  width:29px;
  height:29px;
  -khtml-border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0;
  -webkit-appearance: initial;
  -moz-appearance: initial;
  appearance: initial;
}
.commerce-quantity-plusminus-link.commerce-quantity-plusminus-link-increase a.button{
  background:url(../images/plus.png) no-repeat 0px 0px;
}
.commerce-line-item-views-form input.delete-line-item,
.commerce-line-item-actions #edit-submit{
  font-size:20px;
  color:#4d94c0;
  text-align:center;
  border:1px solid #cccccc;
  background:none;
  outline:none;
  height:29px;
  line-height:26px;
  font-weight: 100;
  padding:0 9px;
  text-transform:none;
}
.commerce-line-item-actions #edit-submit{
  font-size:20px;
  height:42px;
  line-height:42px;
  margin:0 25px;
}
.view-commerce-cart-form .line-item-total{
  font-size:24px;
  color:#b3699d;
  font-weight:700;
  margin:25px 0 30px 0;
}
input[type="submit"],
a.button,
.checkout-buttons .checkout-cancel,
.checkout-buttons .checkout-back{
  height:30px;
  line-height:30px;
  text-transform:uppercase;
  background:#b3699d;
  border: 1px solid transparent;;
  color:#fff;
  font-weight:700;
  font-size:20px;
  padding:0 13px;
}
input[type="submit"]:hover,
a.button:hover,
.checkout-buttons .checkout-cancel:hover,
.checkout-buttons .checkout-back:hover{
  background:#4d94c0;
  color:#fff;
  text-decoration:none;
}
.checkout-buttons .checkout-cancel,
.checkout-buttons .checkout-back{
  background:#999;
}
a.button{
  -webkit-appearance: initial;
  -moz-appearance: initial;
  appearance: initial;
  display:inline-block;
}
.page-checkout #content form{
  margin-top: 55px;
  position:relative;
}
#commerce-checkout-form-checkout fieldset,
#customer-profile-shipping-ajax-wrapper,
#customer-profile-billing-ajax-wrapper{
  width:340px;
  margin-right:40px;
  padding-right:30px;
  display:inline-block;
  vertical-align:top;
  min-height:270px;
  border-right:1px solid #dddddd;
}
#commerce-checkout-form-checkout fieldset.commerce_fieldgroup_pane__group_comment{
  margin-right: 0;
  padding-right: 0;
  border: none;
}
.not-logged-in #commerce-checkout-form-checkout fieldset.commerce_fieldgroup_pane__group_comment{
  position: absolute;
  right: 4px;
  bottom: 0px;
}
.not-logged-in #commerce-checkout-form-checkout fieldset#edit-account{
  margin:0;
  padding:0;
  border:none;
}
.page-checkout #content form label{
  font-size: 16px;
  font-weight: 400;
  color: #333;
  width:110px;
  float: left;
  margin-right: 12px;
  padding-top: 5px;
}
.page-checkout #content fieldset input.form-text{
  width:200px;
}
.page-checkout #content fieldset select{
  width:200px;
}
#commerce-checkout-form-checkout fieldset.checkout-buttons,
fieldset.checkout-buttons{
  position:relative;
  width:100%;
  margin:25px 0 0 0;
  padding:0;
  border:none;
  min-height:inherit;
}
.button-operator{
  display:none;
}
.page-checkout .checkout-buttons .checkout-cancel,
.page-checkout .checkout-buttons .checkout-back{
  position:absolute;
  left:0;
}
.page-checkout .checkout-buttons .form-submit{
  position:absolute;
  right:0;
}
fieldset.commerce_payment{
  margin-top:50px;
}
fieldset.commerce_payment #payment-details{
  display:none;
}
.page-checkout #content fieldset.commerce_shipping label,
.page-checkout #content fieldset.commerce_payment label{
  width:370px;
  line-height: 20px;
  position: relative;
  top: -7px;
}
.page-checkout #content fieldset.commerce_payment input[type="radio"]{
  margin-top:8px;
}
.page-checkout #content fieldset.commerce_payment .form-type-radio{
  margin: 5px 0 10px 0;
  padding-bottom: 10px;
  overflow: hidden;
}
.commerce-paypal-icons .label{
  padding-left:0px;
}
#commerce-checkout-form-review .checkout-help{
  width: 265px;
  margin: 0 auto;
}
.page-checkout #content form .form-item.form-type-item{
  line-height:20px;
}
.page-checkout #content form .form-item.form-type-item label{
  padding-top: 0px;
}
.checkout-completion-message{
  width:100%;
  text-align:center;
  font-size: 20px;
  line-height: 22px;
}
.view-commerce-user-orders table,
.commerce-order-commerce-order table{
  width:100%;
  text-align: left;
}
#commerce-checkout-form-review .checkout_review{
  width: 265px;
  margin: 0 auto;
}
#commerce-checkout-form-review tr.odd,
#commerce-checkout-form-review tr.even{
  background:none;
  border-bottom: none;
  padding: 0;
}
#commerce-checkout-form-review tr.odd td,
#commerce-checkout-form-review tr.even td{
  padding-bottom:15px;
}
#commerce-checkout-form-review table.checkout-review .pane-title td {
  padding-left: 0;
  font-weight: bold;
}
#commerce-checkout-form-review table.checkout-review .pane-data-full{
  padding:0 0 15px 0;
}
#commerce-checkout-form-review .pane-title{
  font-size: 24px;
  font-weight: 700;
  color: #666666;
}
#commerce-checkout-form-review table.checkout-review label{
  display:inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #333;
  width: 110px;
  float: left;
  margin-right: 12px;
}
#commerce-checkout-form-review table.checkout-review .field-name-field-comment .field-label{
  display:none;
}
#commerce-checkout-form-review .name-block .name-block:before {
  content:"Full Name:";
  display:inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #333;
  width: 110px;
  float: left;
  margin-right: 12px;
}
#commerce-checkout-form-review .thoroughfare:before{
  content:"Address 1:";
  display:inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #333;
  width: 110px;
  float: left;
  margin-right: 12px;
}
#commerce-checkout-form-review .premise:before{
  content:"Address 2:";
  display:inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #333;
  width: 110px;
  float: left;
  margin-right: 12px;
}
#commerce-checkout-form-review .locality,
#commerce-checkout-form-review .state,
#commerce-checkout-form-review .postal-code{
  display:inline-block;
}
#commerce-checkout-form-review .locality:before{
  content:"Town/City:";
  display:inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #333;
  width: 110px;
  float: left;
  margin-right: 12px;
}
#commerce-checkout-form-review .state:before{
  content:"State:";
  display:inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #333;
  width: 110px;
  float: left;
  margin-right: 12px;
}
#commerce-checkout-form-review .postal-code:before{
  content:"Postcode:";
  display:inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #333;
  width: 110px;
  float: left;
  margin-right: 12px;
}
#commerce-checkout-form-review .country:before{
  content:"Country:";
  display:inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #333;
  width: 110px;
  float: left;
  margin-right: 12px;
}
#commerce-checkout-form-review .state:before{
  content:"State:";
  display:inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #333;
  width: 110px;
  float: left;
  margin-right: 12px;
}
/* end CART */

/* start USER REGISTER PASSWORD */
.page-user-password h1,
.page-user.not-logged-in .tabs{
  display:none;
}
.page-user #content{
  width:950px;
  margin:0 auto;
}
.page-user-password #content{
  width:660px;
  margin:0 auto;
}
.page-user-register #content{
  width:1040px;
  margin:0 auto;
}
.page-user-register label,
.page-user-password label{
  float:left;
  margin-right:12px;
  font-size:16px;
  color:#333333;
  padding-top: 5px;
  width:initial;
}
.page-user-register .description{
  margin-left:147px;
  font-size:14px;
  color:#666666;
}
.page-user-register label{
  width:135px;
}
#block-block-11{
  margin:0;
  width:460px;
  margin-right:150px;
  margin-top:55px;
  font-size:24px;
  font-weight:700;
  color:#666666;
}
.user-info-from-cookie{
  margin:0;
  width:430px;
  margin-right:150px;
  margin-top:10px;
  font-size:16px;
  font-weight:400;
  color:#333;
  position:relative;
}
.user-info-from-cookie label{
  font-size:16px;
  font-weight:400;
  color:#333;
}
.user-info-from-cookie description{
  font-size:14px;
  font-weight:400;
  color:#666;
}
.user-info-from-cookie .field-type-addressfield{
  position: absolute;
  left: 610px;
  top: -40px;
  width:450px;
}
form fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
form fieldset legend{
  font-size:24px;
  font-weight:700;
  color:#666666;
  margin-bottom: 19px;
}
.field-type-addressfield select{
  width: 280px;
  border: 1px solid #ccc;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
}
.user-info-from-cookie .form-actions{
  text-align: center;
  width: 1000px;
  margin-top: 85px;
}
input[type="text"],
input[type="password"],
input[type="email"]{
  width:280px;
  height:30px;
  line-height:30px;
  font-size:18px;
  color:#666;
  padding:0 10px;
  outline:none;
  border:1px solid #cccccc;
}
#admin-menu-wrapper input[type="text"]{
  height:initial;
  line-height:initial;
}
.page-user-password #edit-actions{
  float: right;
  top: -45px;
  position: relative;
  left: -75px;
}
.page-user #edit-actions{
  text-align:center;
}
.page-user input[type="submit"],
.page-user a.button{
  height:30px;
  line-height:30px;
  text-transform:none;
  background:#4d94c0;
  font-weight:400;
  padding:0 25px;
}
.page-user input[type="submit"]:hover,
.page-user a.button:hover{
  background:#b3699d;
}
#block-block-10 a.button{
  margin-top:65px;
}
.region-content2{
  clear:both;
}
.page-user-password .region-content2{
  top: -30px;
  position: relative;
}
.password-strength,
div.password-confirm,
div.form-item div.password-suggestions{
  display:none!important;
}
#block-block-8{
  position: relative;
  top: -20px;
}
#block-block-8 a{
  text-decoration:underline;
}
#user-login label{
  margin-bottom:5px;
}
.page-user #user-login #edit-actions{
  text-align:left;
  position:relative;
  top:47px;
}
.form-type-password.form-item-pass .description{
  display:none;
}
#block-block-9{
  margin-top:35px;
}
#block-block-10{
  float:right;
  margin-top:35px;
  position: relative;
  z-index: 10;
}
form#user-pass-reset{
  margin-top: 35px;
}
/* end USER REGISTER PASSWORD */

/* start PRODUCT */
#block-views-productpage-block{
  width:572px;
  margin-right:42px;
}
.view-productpage img{
  border:1px solid #ccc;
}
.view-productpage .views-field-body img,
.view-productpage .views-field-nothing img{
  border:none
}
.view-productpage .productpayments{
  font-weight:700;
}
.view-productpage .productpayments span{
  display: inline-block;
  position: relative;
  top: -5px;
}
.view-productpage .productpayments .paypal{
  padding: 0 5px 3px 10px;
}
.jcarousel-skin-tango .jcarousel-container-horizontal {
  width: 484px;
  padding: 0 44px;
}
.jcarousel-skin-tango .jcarousel-clip-horizontal {
  width: 484px;
  height: 113px;
}
.jcarousel-skin-tango .jcarousel-item {
  height: 113px;
}
.jcarousel-skin-tango .jcarousel-item-horizontal {
  margin-right: 12px;
}
.jcarousel-skin-tango .jcarousel-container {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0;
  background: none;
  border: none;
}
.views-slideshow-controls-bottom{
  margin-top:25px;
}
.jcarousel-skin-tango img{
  opacity:0.5;
  cursor:pointer;
}
.jcarousel-skin-tango img:hover,
.jcarousel-skin-tango .active img{
  opacity:1;
  border: 2px solid #4d94c0;
  width:107px;
}
.jcarousel-skin-tango .jcarousel-prev-horizontal{
  left: 5px;
  width: 34px;
  height: 23px;
  cursor: pointer;
  background: url(../images/galleryleft.png) no-repeat 0px 0px;
}
.view-productpage .jcarousel-skin-tango .jcarousel-prev-horizontal:hover,
.view-productpage .jcarousel-skin-tango .jcarousel-prev-horizontal:focus,
.view-productpage .jcarousel-skin-tango .jcarousel-prev-horizontal:active{
  background: url(../images/galleryleft.png) no-repeat 0px 0px;
}
.jcarousel-skin-tango .jcarousel-next-horizontal{
  right: 5px;
  width: 34px;
  height: 23px;
  cursor: pointer;
  background: url(../images/galleryright.png) no-repeat 0px 0px;
}
.jcarousel-skin-tango .jcarousel-prev-horizontal,
.jcarousel-skin-tango .jcarousel-next-horizontal{
  top: 50%;
  margin-top: -12px;
}
.view-productpage .jcarousel-skin-tango .jcarousel-next-horizontal:hover,
.view-productpage .jcarousel-skin-tango .jcarousel-next-horizontal:focus,
.view-productpage .jcarousel-skin-tango .jcarousel-next-horizontal:active{
  background: url(../images/galleryright.png) no-repeat 0px 0px;
}
#sb-info, #sb-info-inner {
  width: 100%;
  height: 68px;
  position: absolute;
  top: 0px;
}
#sb-info{
  top:-14px;
}
#sb-counter {
  margin-top: 25px;
}
#sb-nav a#sb-nav-close {
  position: absolute;
  top: 0px;
  right: 1px;
  z-index: 999;
  background: url(../images/popupclose.png) no-repeat 0 0;
  width:39px;
  height:39px;
}
.rightshadowbox div {
  background: url(../images/popupright.png) no-repeat 100% 49%;
  position: absolute;
  z-index: 5000;
  width: 41px;
  height: 27px;
  top: 49%;
  right:-48px;
}
.leftshadowbox div {
  background: url(../images/popupleft.png) no-repeat 100% 49%;
  position: absolute;
  z-index: 5000;
  width: 41px;
  height: 24px;
  top: 49%;
  left: -50px;
}
#block-views-productpage-block,
#block-views-productpage-block-1{
  display:inline-block;
  vertical-align:top;
  margin-top:0px;
}
#block-views-productpage-block-1{
  width:551px;
  position:relative;
  margin-bottom:0;
}
#block-views-productpage-block-1 .views-field-commerce-price{
  position:absolute;
  right:0px;
  top:0px;
}
#block-views-productpage-block-1 .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-price .price-amount{
  font-size:24px;
  color:#b3699d;
  font-weight:700;
}
#block-views-productpage-block-1 .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-price {
  text-align:right;
}
#block-views-productpage-block-1 .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-list .price-amount{
  font-size:18px;
  color:#666;
  font-weight:400;
  background: url(../images/priceoldbig.png) no-repeat 0px 4px;
}
#block-views-productpage-block-1 .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-list{
  top:20px;
  text-align: right;
}
#block-views-productpage-block-1 .form-item-quantity{
  position:absolute;
  margin:0;
  right:215px;
  top:50px;
}
#block-views-productpage-block-1 .form-item-quantity label{
  font-size:12px;
  font-weight:400;
  color:#666666;
  margin-left: 22px;
}
#block-views-productpage-block-1 input[type="submit"]{
  position:absolute;
  margin:0;
  right:0px;
  top:55px;
  font-size:20px;
  font-weight:700;
  height:43px;
  line-height:43px;
  width:205px;
  text-align:left;
  padding-left:65px;
  text-transform:uppercase;
  background:url(../images/cartbig.png) no-repeat 17px 11px #b3699d;
}
#block-views-productpage-block-1 input[type="submit"]:hover{
  background:url(../images/cartbig.png) no-repeat 17px 11px #508ec7;
}
#block-views-productpage-block-1 .views-field-field-delivery-time,
#block-views-productpage-block-1 .views-field-php,
#block-views-productpage-block-1 .views-field-field-production-time{
  position:absolute;
  top:160px;
  right:0px;
  font-size:18px;
  line-height: 22px;
  color:#666666;
  background:url(../images/deliverytimeproduct.png) no-repeat 0px 0px;
  padding-left:35px;
  text-align:right;
  width:265px;
}
.views-field-php span.between{
  font-size:18px;
}
#block-views-productpage-block-1 .views-field-field-production-time{
  top:132px;
}
#block-views-productpage-block-1 .views-field-field-production-time .views-label-field-production-time{
  float: left;
  margin-right: 5px;
  margin-left:17px;
}
#block-views-productpage-block-1 .views-field-field-color-term,
#block-views-productpage-block-1 .views-field-field-size-term,
#block-views-productpage-block-1 .views-field-field-material,
#block-views-productpage-block-1 .views-field-body{
  padding-bottom: 21px;
}
#block-views-productpage-block-1 .views-field-field-material{
  padding-bottom: 21px;
}
#block-views-productpage-block-1 .views-field-field-color-term .views-label,
#block-views-productpage-block-1 .views-field-field-size-term .views-label,
#block-views-productpage-block-1 .views-field-field-material .views-label,
#block-views-productpage-block-1 .views-field-body .views-label,
#block-views-productpage-block-1 .views-field-field-sizes .views-label{
  font-size:18px;
  font-weight:700;
  color:#666666;
  padding-bottom: 12px;
  display: block;
}
#block-views-productpage-block-1 .views-field-body .views-label{
  font-size:16px;
}
#block-views-productpage-block-1 .views-field-field-color-term ul,
#block-views-productpage-block-1 .views-field-field-size-term ul{
  padding:0;
  margin:0;
}
#block-views-productpage-block-1 .views-field-field-color-term li,
#block-views-productpage-block-1 .views-field-field-size-term li{
  width:33px;
  height:33px;
  line-height:33px;
  text-align:center;
  display:inline-block;
  vertical-align:top;
  border:1px solid #cccccc;
  margin-right:2px;
  font-size:20px;
  color:#666666;
  list-style:none;
}
#block-views-productpage-block-1 .views-field-field-sizes{
  margin-top:15px;
  color: #666666;
}
.entity-field-collection-item.field-collection-item-field-sizes{
  width: 102px;
  display: inline-block;
  vertical-align: top;
  margin-right: 3px;
  margin-top: 5px;
}
#block-views-productpage-block-1 .field-name-field-addition-size-label{
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  padding-bottom: 8px;
  display: block;
  width: 102px;
  text-align: center;
}
#block-views-productpage-block-1 .field-name-field-additional-size-value{
  color: #666666;
  font-size: 16px;
  width: 100px;
  text-align: center;
  border: 1px solid #ccc;
  display: inline-block;
  height: 30px;
  line-height: 30px;
}
#block-views-productpage-block-1 .views-field-sku{
  position:absolute;
  top:0;
  right:124px;
}
#block-views-productpage-block-1 .views-field-sku .views-label{
  float:left;
  margin-right:5px;
  font-weight: 700;
  color:#666666;
}
#block-views-productpage-block-1 .views-field-sku .field-content{
  font-size: 18px;
  color: #999;
}
#block-views-productpage-block-1 .views-field-body .views-label{
  padding-bottom: 18px;
}
#block-views-productpage-block-1 .views-field-field-material ul{
  margin:0;
  padding:0;
}
#block-views-productpage-block-1 .views-field-field-material li{
  list-style:none;
  background:url(../images/materialsli.png) no-repeat 13px 11px;
  padding-left:27px;
  padding-bottom:2px;
  padding-top:2px;
  font-size:18px;
  color:#666;
}
#block-views-productpage-block-1 .views-field-nothing{
  font-size: 18px;
  line-height: 22px;
  color: #666;
  margin-bottom:15px;
}
#block-views-productpage-block-1 .views-field-nothing-2{
  font-size: 18px;
  line-height: 22px;
  position: absolute;
  top: 26px;
  left: 337px;
}
#block-views-productpage-block-1 .views-field-expression,
#block-views-productpage-block-1 .views-field-expression-1,
#block-views-productpage-block-1 .views-field-expression-2,
#block-views-productpage-block-1 .views-field-expression-3{
  width:102px;
  display:inline-block;
  vertical-align:top;
  margin-right:3px;
  margin-top:25px;
}
#block-views-productpage-block-1 .views-field-expression,
#block-views-productpage-block-1 .views-field-expression-1,
#block-views-productpage-block-1 .views-field-expression-2,
#block-views-productpage-block-1 .views-field-expression-3{
  margin-top:-10px;
}
#block-views-productpage-block-1 .views-field-field-weight{
  margin-right:16px;
}
#block-views-productpage-block-1 .views-label-expression,
#block-views-productpage-block-1 .views-label-expression-1,
#block-views-productpage-block-1 .views-label-expression-2,
#block-views-productpage-block-1 .views-label-expression-3{
  font-size:18px;
  font-weight:400;
  color:#666666;
  padding-bottom: 8px;
  display: block;
  width:102px;
  text-align:center;
}
#block-views-productpage-block-1 .field-group-expression{
  color:#666666;
  font-size:16px;
  width:100px;
  text-align:center;
  border:1px solid #ccc;
  height:30px;
  line-height:30px;
  margin-bottom: 6px;
}
#block-views-productpage-block-1 .views-field-commerce-shipping-price{
  position: absolute;
  top: 104px;
  right: 0px;
  font-size: 18px;
  line-height: 22px;
  color: #666666;
  padding-left: 40px;
  text-align: right;
  width: 228px;
}
#block-views-productpage-block-1 .views-field-commerce-shipping-price .views-label{
  display: inline-block;
  margin-right: 5px;
}
#block-views-productpage-block-1 .views-field-commerce-shipping-price .field-content{
  display: inline-block;
}
#block-views-productpage-block-1 .views-field-body{
  font-family:Verdana;
  font-size:16px;
  line-height:20px;
  color:#333;
  margin-top:28px;
}
.node-webform .webform-component input[type="text"],
.node-webform .webform-component input[type="email"]{
  width:100%;
}
.node-webform .webform-component input[readonly="readonly"]{
  background: #eee;
}
.not-front.node-type-product-display #page{
  margin-bottom:30px;
}
#comments{
  margin:0;
}
article.comment{
  margin:5px 0 20px 0;
}
.comment .submitted{
  font-style: italic;
  margin: 0 0 5px 0;
}
.comment-form .form-item {
  margin: 0 0 5px 0;
}
.comments__form-title{
  font-size:18px;
}
#block-views-productpage-block-1 .views-field-field-color-term li span{
  text-indent: -9999px;
  display: inline-block;
  width: 23px;
  height: 23px;
  text-align: center;
  margin-top: 5px;
}
span.Any{
  background: #fff; /* For browsers that do not support gradients */
  /* For Safari 5.1 to 6.0 */
  background: -webkit-linear-gradient(left,red,orange,yellow,green,blue,indigo,violet);
  /* For Opera 11.1 to 12.0 */
  background: -o-linear-gradient(left,red,orange,yellow,green,blue,indigo,violet);
  /* For Fx 3.6 to 15 */
  background: -moz-linear-gradient(left,red,orange,yellow,green,blue,indigo,violet);
  /* Standard syntax */
  background: linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet);
}
span.Beige{
  background:#f5f5dc;
}
span.Black{
  background:#000000;
}
span.Blue{
  background:#3100FF;
}
span.Brown{
  background:#663300;
}
span.Coral{
  background:#ff7f50;
}
span.Dark.Blue{
  background:#000080;
}
span.Dark.Brown{
  background:#654321;
}
span.Gold{
  background:#ffd700;
}
span.Grey{
  background:#808080;
}
span.Green{
  background:#00FF00;
}
span.Ivory{
  background:#fffff0;
}
span.Light.Blue{
  background:#add8e6;
}
span.Light.Brown{
  background:#f4a460;
}
span.Light.Champagne{
  background:#F9E3CB;
}
span.Light.Gray{
  background:#d3d3d3;
}
span.Light.Green{
  background:#90EE90;
}
span.Light.Pink{
  background:#ffb6c1;
}
span.Lilac{
  background:#C8A2C8;
}
span.Lime{
  background:#32cd32;
}
span.Mallow{
  background:#d3cbd3;
}
span.Melange{
  background:#bd7157;
}
span.Milk{
  background:#FFF7D9;
}
span.Milky.White{
  background:#b7bdac;
}
span.Multicolor{
  background: #fff; /* For browsers that do not support gradients */
  /* For Safari 5.1 to 6.0 */
  background: -webkit-linear-gradient(left,red,orange,yellow,green,blue,indigo,violet);
  /* For Opera 11.1 to 12.0 */
  background: -o-linear-gradient(left,red,orange,yellow,green,blue,indigo,violet);
  /* For Fx 3.6 to 15 */
  background: -moz-linear-gradient(left,red,orange,yellow,green,blue,indigo,violet);
  /* Standard syntax */
  background: linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet);
}
span.Neon{
  background:#00FF33;
}
span.Ochre{
  background:#CC7722;
}
span.Orange{
  background:#ffa500;
}
span.Pastel.Colours{
  background:#AEC6CF;
}
span.Pink{
  background:#ffc0cb;
}
span.Purple{
  background:#551a8b;
}
span.Red{
  background:#ff0000;
}
span.Rosy{
  background:#bc8f8f;
}
span.Tiffany{
  background:#0abab5;
}
span.Turquoise{
  background:#40e0d0;
}
span.Vinous{
  background:#800025;
}
span.Violet{
  background:#ee82ee;
}
span.White{
  background:#ffffff;
}
span.Yellow{
  background:#FFFF00;
}
.node-type-product-display article{
  display:none;
}
#block-block-13{
  margin:20px 0 10px 0;
  clear:both;
  float:none;
  overflow:hidden;
}
.node-type-product-display #block-block-13{
  width: 551px;
  position: relative;
  left:614px;
  margin-top:0px;
}
#block-block-13 a:focus{
  border:none;
}
#block-block-13 .block-content a img{
  width:40px;
  height:40px;
}
#block-block-13 .block-content > p{
  float:left;
  margin-right:7px;
}
#block-block-13 .block-content > .fb_iframe_widget{
  top: 3px;
}
#block-block-13 .block-content > span{
  display: inline-block;
  margin: 0;
  position: relative;
  top: 5px;
}
/* end PRODUCT */


/* start PRODUCTLIST */
.sidebar-first aside{
  padding-top: 35px;
}
aside .region-sidebar-first .block-title{
  font-size:18px;
  line-height:30px;
  font-weight:700;
  margin-bottom: 0;
  font-family:calibri;
}
aside .region-sidebar-first .block-title a{
  color:#ffffff;
  background:url(../images/sidemenuopen.png) no-repeat 247px 11px #508ec7;
  display:inline-block;
  width:257px;
  height:30px;
  text-align:left;
  padding-left:13px;
  overflow:hidden;
}
aside .region-sidebar-first .block-title:after{
  display:none;
}
aside .region-sidebar-first ul{
  margin:0;
  padding:0;
}
#block-menu-block-5 ul ul{
  padding-left:32px;
  padding-bottom:17px;
}
#block-menu-block-5 ul li{
  list-style:none;
  line-height: 23px;
}
#block-menu-block-5 ul li a{
  color:#4d95c0;
  font-size:18px;
  font-weight:700;
  padding-left:14px;
  background:url(../images/sidemenuli.png) no-repeat 0px 7px;
}
#block-menu-block-5 ul li.expanded a{
  color:#333333;
  background:none;
}
#block-menu-block-5 ul li.expanded li a{
  color:#4d95c0;
  font-weight:400;
  background:url(../images/sidemenuli.png) no-repeat 0px 7px;
}
.block-facetapi li{
  list-style:none;
  margin-left:18px;
  font-weight:700;
  color:#4d95c0;
  font-size:18px;
  line-height: 23px;
}
.block-facetapi li a{
  font-weight:400;
  color:#4d95c0;
  font-size:18px;
}
.sidebar-first #content{
  padding-top: 22px;
}
#views-exposed-form-faceted-products-page .form-item-sort-bef-combine label,
.views-widget-per-page label{
  display:inline-block;
}
#superfish-2.sf-menu li {
  z-index: 50;
}
.views-exposed-form .views-widget-filter-combine{
  text-align: left;

}
.views-exposed-form{
  position:relative;
}
.views-exposed-form .form-item-items-per-page{
  text-align: left;
}
.views-exposed-widgets{
  margin:0;
}
#views-exposed-form-faceted-products-page .form-item-sort-bef-combine select{
  font-size:14px;
  color:#333;
  padding:1px 8px;
  border:1px solid #abadb3;
  outline:none;
  margin: 25px 0 22px 0;
}
.view-header {
  font-size: 14px;
  color: #333;
  float: left;
  position: relative;
  top: 42px;
}
.view-faceted-products .view-empty{
  font-size:18px;
  color:#333;
}
.block-facetapi .facetapi-facet-field-productfield-color-term li,
.block-facetapi .facetapi-facet-field-color-term li{
  line-height:33px;
  margin-bottom: 5px;
}
.block-facetapi .facetapi-facet-field-productfield-color-term li input[type="checkbox"],
.block-facetapi .facetapi-facet-field-color-term li input[type="checkbox"]{
  opacity: 0;
  position: absolute;
  left: 18px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.block-facetapi li span.facetcolor{
  width:33px;
  height:33px;
  line-height:33px;
  text-align:center;
  display:inline-block;
  vertical-align:top;
  margin-right:7px;
  font-size:20px;
  color:#666666;
  list-style:none;
  cursor:pointer;
}
.block-facetapi li:hover a span.facetcolor,
.block-facetapi li span.facetcolor{
  border:2px solid #508ec7;
  width:31px;
  height:31px;
}
.block-facetapi li a span.facetcolor{
  border:1px solid #cccccc;
  width:33px;
  height:33px;
}
.block-facetapi li span.facetcolor span{
  display:inline-block;
  width: 23px;
  height: 23px;
  text-align: center;
  margin-top: 5px;
}
.block-facetapi li a span.facetcolor span{
  margin-top: 5px;
}
.block-facetapi li span.facetcolor span,
.block-facetapi li:hover a span.facetcolor span{
  margin-top: 4px;
}
.block-facetapi .facetapi-facet-field-productfield-color-term li a,
.block-facetapi .facetapi-facet-field-color-term li a{
  line-height:33px;
}
/* end PRODUCTLIST */

/* start BLOG */
#block-superfish-2{
  text-align: center;
  margin-bottom: 20px;
}
#superfish-2:before{
  content: '';
  background: #000;
}
#superfish-2{
  display: inline-block;
  text-align: left;
  z-index: 0;
}
#superfish-2.sf-menu.menu>li{
  cursor: pointer;
  margin: 0 10px;
}
#superfish-2.sf-menu.menu>li>span{
  font-size: 20px;
  color: #333;
}
#superfish-2.sf-menu.menu li ul{
  top: 30px;
  padding: 5px 0;
  background: #fff;
  -webkit-box-shadow: 0 0 2px #000;
  -moz-box-shadow: 0 0 2px #000;
  box-shadow: 0 0 2px #000;
}
#superfish-2.sf-menu.menu li ul li{
  padding: 5px 10px;
  width: 6em;
}
#superfish-2.sf-menu.menu li ul li a {
  font-size: 18px;
  color: #b3699d;
}
#block-menu-menu-tag-menu .block-title{
  font-size:22px;
}
#block-menu-menu-tag-menu ul{
  padding:0;
  margin:0;
}
#block-menu-menu-tag-menu ul ul{
  padding:0 0 0 15px;
  margin:0;
}
#block-menu-menu-tag-menu li{
  list-style:none;
}
#block-menu-menu-tag-menu li a {
  color: #4d95c0;
  font-weight: 400;
  background: url(../images/sidemenuli.png) no-repeat 0px 7px;
  font-size: 18px;
  padding-left: 14px;
}
#block-menu-menu-tag-menu ul ul li a{
  font-size:16px;
}
.field-name-field-blog-entry-image,
.view-blog .views-field-field-blog-entry-image{
  float: left;
  margin: 0 20px 10px 0;
}
.field-name-field-displayed-date,
.view-blog .views-field-field-displayed-date{
  font-style: italic;
  margin-bottom: 8px;
}
.field-name-field-tags .field-label{
  margin-right:8px;
}
.field-name-field-tags .field-item{
  float:left;
  margin-right:8px;
}
.block-flippy{
  margin-top:20px;
  font-size: 20px;
}
ul.flippy,
ul.flippy li{
  margin:0;
  padding:0;
}
ul.flippy li.next{
  position: absolute;
  right: 0px;
}
.view-blog .views-row{
  overflow:hidden;
  clear:both;
  float:none;
  margin-bottom:20px;
  padding-bottom:20px;
  border-bottom:1px solid #ccc;
}
.view-blog .views-row.views-row-last{
  border:none;
}
.view-blog .views-field-title{
  font-size: 22px;
  margin-bottom: 8px;
}
.view-blog .views-field-nothing{
  text-align: right;
}
/* end BLOG */

.grippie{
  display:none;
}
textarea#edit-commerce-fieldgroup-pane-group-comment-field-comment-und-0-value{
  resize:none;
}
#block-commerce-cart-cart .links.inline li,
.preheader,
input[type="submit"],
a.button,
.flexslider .views-field-field-add-button a{
  transition:all 0.6s linear;
}
.maintenance-page{
  font-size: 35px;
  line-height: 45px;
  text-align: center;
}
.page-node .tabs.primary li:nth-of-type(3) {
  display: none;
}
.page-node.node-type-product-display .tabs.primary li:nth-of-type(3) {
  display: initial;
}


#commerce-shipping-service-ajax-wrapper{
  display:none;
}
fieldset#edit-commerce-payment{
  display:none;
}
.street-block label.element-invisible{
  height: 24px;
  display: inline-block;
  position: relative!important;
}




@media (max-width: 1200px){
  .logged-in #block-menu-block-3 {
    top: 20px;
    left: 283px;
  }
  .view-deals .views-row,
  .view-deals .views-row.views-row-1,
  .view-deals .views-row.views-row-3{
    max-width: 28%;
    margin: 0 2%;
    float:none;
  }
  .region-footer2{
    height:initial;
    padding: 75px 0 50px 0;
  }
  .flex-direction-nav{
    width:100%;
  }
  .flexslider .views-field-field-add-button{
    width:100%;
  }
  .view-productslistwithoutfilter .views-row,
  .view-search .views-row,
  .view-faceted-products .views-row{
    width: 29%;
    margin: 0 2% 40px 2%;
  }
  .view-productslistwithoutfilter .views-row:nth-of-type(4n),
  .view-search .views-row:nth-of-type(4n),
  .view-faceted-products .views-row:nth-of-type(3n){
    margin: 0 2% 40px 2%;
    position: relative;
  }

  .view-productslistwithoutfilter .view-content,
  .view-search .view-content,
  .view-faceted-products .view-content{
    text-align:center;
  }
  .view-productslistwithoutfilter .view-content .views-row,
  .view-search .view-content .views-row,
  .view-faceted-products .view-content .views-row{
    text-align:center;
  }
  aside .region-sidebar-first .block-title a{
    width:100%;
    background: url(../images/sidemenuopen.png) no-repeat 98% 11px #508ec7;
  }
  .breadcrumb{
    margin-left:10px;
  }
  #block-views-productpage-block,
  #block-views-productpage-block-1{
    margin: 20px auto 0;
    display: block;
  }
  .node-type-product-display #block-block-13 {
    width: 551px;
    left: initial;
    margin: 0 auto;
  }
  .node-type-product-display .addtoany_list{
    line-height: 32px;
    display: block;
    margin: 0 auto;
    width: 570px;
  }
  article,
  .profile{
    padding:0 10px;
  }
  #views-form-commerce-cart-form-default{
    overflow:auto;
  }
  #commerce-checkout-form-checkout fieldset, #customer-profile-shipping-ajax-wrapper, #customer-profile-billing-ajax-wrapper,
  #commerce-checkout-form-checkout fieldset.commerce_fieldgroup_pane__group_comment{
    display:block;
    margin:0 auto;
    border:none;
    width: 91%;
  }
  .view-header {
    margin-left: 10px
  }
  .formatter-label-row .commerce-price-savings-formatter-savings {
    top: 22px;
    left: 0;
  }
  .commerce-price-savings-formatter-label-row .commerce-price-savings-formatter-savings {
    top: 22px;
    left: 0;
  }
  .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-savings {
    top: 20px;
    left: 35px;
  }
}
@media (max-width: 1100px){
  .jcarousel-skin-tango .jcarousel-item {
    height: auto;
  }
  .view-header{
    top: 0;
  }
  #edit-items-per-page{
    padding: 1px;
  }
  #edit-items-per-page select{
    font-size: 18px;
  }
  .views-exposed-widgets {
  }
  #block-block-5,
  #block-commerce-cart-cart{
    right:50px;
  }
  #block-block-11,
  #block-block-10,
  #block-block-9,
  .page-user form#user-login,
  .page-user form.user-info-from-cookie,
  #block-block-8,
  #block-block-6,
  form#user-pass,
  #block-block-7{
    float:none;
    display: block;
    margin: 20px auto;
    width: 93%;
  }
  .user-info-from-cookie .form-actions{
    width: 100%;
    margin-top: 0;
    text-align: left!important;
  }
  .user-info-from-cookie .field-type-addressfield {
    position: relative;
    left: initial;
    top: initial;
    width: 100%;
  }
  #block-block-8{
    margin-top:0px;
  }
  #block-block-10 a.button {
    margin-top: 0;
  }
  .page-user #user-login input[type="submit"]{
    top: -55px;
    position: relative;
  }
  .page-user #content {
    width: 100%;
    margin: 0 auto;
  }
  .page-user-register .description {
    margin-left: 0;
  }
  .confirm-parent, .password-parent{
    width: auto;
  }
  form#user-pass .form-actions{
    position: relative;
    text-align: left;
    width: 100%;
    margin: 0;
    left: 0;
    top:0px;
    z-index:10;
  }
  #block-block-7{
    padding-top:40px;
  }
  #footer #block-menu-block-1 {left: 35%;width: 25%;text-align: center;position: absolute;}
  #footer #block-block-1 {font-size: 16px;}
  .prefooter {padding-top: 30px;height: 147px;}
  .view-productpage .productpayments {margin-top: 6%;}
  #block-views-productpage-block-1 .views-field-sku {position: static;right: 0;}
  #block-views-productpage-block-1 .views-field-sku .views-label {float: none;}
  #block-views-productpage-block-1 .views-field-commerce-price {
    position: static;
    margin-top: 0;
    display: inline-block;
    padding-left: 15px;
    width: 71%;
    font-size: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    clear: both;
    vertical-align: bottom;
  }
  #block-views-productpage-block-1 .views-field-commerce-price .field-content{
    float: right;
    width: 205px;
  }
  #block-views-productpage-block-1 .views-field-add-to-cart-form{
    display: inline-block;
    padding-left: 6%;
    width: 71%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  #block-views-productpage-block-1 .views-field-add-to-cart-form .field-content>form>div{
    overflow: hidden;
  }
  .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-price .price-amount{
    line-height: 1em;
  }
  #block-views-productpage-block-1 input[type="submit"]{
    float: right;
    position: static;
    margin-top: 9px;
  }
  #block-views-productpage-block-1 .form-item-quantity{
    float: left;
    position: static;
    margin-top: 0;
  }
  #block-views-productpage-block-1 .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-price .price-amount {font-size: 40px;}
  #block-views-productpage-block-1 .views-field-nothing {font-size: 28px;margin-bottom: 15px;text-align: center;}
  #block-views-productpage-block-1 .form-item-quantity label {font-size: 22px;margin-left: 0;text-align: center;margin-bottom: 5%;}
  .view-commerce-cart-form td.views-field-commerce-unit-price .commerce-price-savings-formatter-price .price-amount, .view-commerce-cart-form td.views-field-commerce-total .commerce-price-savings-formatter-price .price-amount, .view-commerce-cart-form td.views-field-commerce-shipping-price {font-size: 28px;}
  #block-views-productpage-block-1 .views-field-field-delivery-time, #block-views-productpage-block-1 .views-field-php, #block-views-productpage-block-1 .views-field-field-production-time {margin-top: 13%;font-size: 21px;width: 62%;white-space: pre-line;text-align: left;}
  #block-views-productpage-block-1 .views-field-commerce-shipping-price {font-size: 22px;margin-top: 50px;}
  #block-views-productpage-block-1 .views-field-nothing-2 {font-size: 21px;top: 11.4%;margin-left: -16%;display: none;}
  #block-commerce-cart-cart .block-content {
    right: 26px;
  }
  .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-list .price-amount {color: red;}
  .commerce-price-savings-formatter-list .price-amount {text-decoration: line-through;background: none;}
  .view-commerce-cart-form table {width: 100%;margin: 0;text-align: center;}
  .views-label .views-label-line-item-title span {font-weight:bold;}
  .view-commerce-cart-form td {padding-top: 10px;padding-left: 1%;text-align: left;}
  .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-list {
    position: static;
    margin-top: 0;
    margin-right: 10px;
  }
  aside .region-sidebar-first .block-title {display: none;}
  .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-list .price-amount {font-size: 20px;}
  .line-item-summary .line-item-quantity {float: left;font-size: 22px;}
  .line-item-total {font-size: 22px;}
  .flex-direction-nav {z-index: 8;}
  .view-productslistwithoutfilter .views-row .views-field-title,
  .view-search .views-row .views-field-title,
  .view-faceted-products .views-row .views-field-title {
    line-height: 20px;
    height: 80px;
    max-height: 80px;}
  .view-productslistwithoutfilter .views-row .views-field-title a,
  .view-search .views-row .views-field-title a,
  .view-faceted-products .views-row .views-field-title a {font-family: Calibri;font-size: 24px;}
  .views-field-add-to-cart-form input[type="submit"] {
    font-family: Calibri;font-size: 24px;height: 50px;line-height: 24px;}
  .view-productslistwithoutfilter .views-row,
  .view-search .views-row, .view-faceted-products .views-row {
    width: 29%;margin: 0 2% 81px 2%;}
  #block-views-productpage-block-1 .views-field-expression,
  #block-views-productpage-block-1 .views-field-expression-1,
  #block-views-productpage-block-1 .views-field-expression-2,
  #block-views-productpage-block-1 .views-field-expression-3 {margin-top: 0;}
  .not-front #page {margin-top: 19%;margin-bottom: 0px;}
  .view-productslistwithoutfilter .view-content, .view-search .view-content, .view-faceted-products .view-content {
    text-align: center;
    overflow: hidden;
  }
  .view-productslistwithoutfilter .views-field-add-to-cart-form input[type="submit"],
  .view-search .views-field-add-to-cart-form input[type="submit"],
  .view-faceted-products .views-field-add-to-cart-form input[type="submit"] {
    position: relative;
    top: -67px;
    right: -78px;
  }
  .page-checkout #content form label {font-size: 21px;width: 100%;float: none;}
  #commerce-checkout-form-checkout fieldset, #customer-profile-shipping-ajax-wrapper, #customer-profile-billing-ajax-wrapper {min-height: 0;margin-top: 3%;}
  #commerce-checkout-form-checkout fieldset, #customer-profile-shipping-ajax-wrapper, #customer-profile-billing-ajax-wrapper, #commerce-checkout-form-checkout fieldset.commerce_fieldgroup_pane__group_comment {width: 92%;}
  .not-logged-in #commerce-checkout-form-checkout fieldset.commerce_fieldgroup_pane__group_comment {position: relative;right: 18px;bottom: 0px;}
  .page-checkout .checkout-buttons .form-submit {margin-top: 1%;    position: relative;right: 0;width: 100%;}
  .page-checkout #content fieldset.commerce_payment .form-type-radio {padding-bottom: 0px;}
  .page-checkout #content fieldset input.form-text {width: 400px;margin-top: 2%;}
  .not-logged-in #commerce-checkout-form-checkout fieldset#edit-account {margin-left: 2%;margin-bottom: 3%;}
  #block-block-5,
  #block-commerce-cart-cart {
    top: 5px;
    right: 70px;
  }
  #block-superfish-1 {
    font-size: 19px;
  }
  #block-superfish-1 li.sf-depth-1 {
    margin: 0 18px;
  }
  #block-superfish-1 ul ul {
    margin-top: -12px;
  }
  .page-user input[type="submit"], .page-user a.button {height: 45px;padding: 0 45px;line-height: 2;font-size: 23px;}
  .user-info-from-cookie label {font-size: 23px;}
  #block-views-productpage-block-1 .views-field-field-production-time .views-label-field-production-time {margin-left: 0px;}
  .views-field-php span.between {font-size: 22px;}
  #block-views-productpage-block-1 .views-field-field-length .views-label,
  #block-views-productpage-block-1 .views-field-field-width .views-label,
  #block-views-productpage-block-1 .views-field-field-height .views-label,
  #block-views-productpage-block-1 .views-field-field-weight .views-label {
    font-size: 22px;
    margin-top: 20px;
  }
  #block-views-productpage-block-1 .views-field-view{
    clear: both;
  }
  #block-views-productpage-block-1 .views-field-field-color-term .views-labell{
    padding-bottom: 0;
  }
  #block-views-productpage-block-1 .views-field-field-color-term,
  #block-views-productpage-block-1 .views-field-sku{
    padding-bottom: 0;
    display: inline-block;
    width: 27%;
    font-size: 0;
  }
  #block-views-productpage-block-1 .views-field-sku{
    vertical-align: top;
    margin-top: 20px;
  }
  #block-views-productpage-block-1 .field-group-expression {
    font-size: 20px;}
  .sidebar-first .region-sidebar-first {float: left;margin-left: 0;margin-right: 0;padding-left: 0;width:100%;}
  .sidebar-first #content {width: 100%;margin-left: 0;}
  #block-menu-menu-tag-menu ul ul li a {font-size: 22px;}
  .view-blog .views-row {
    padding: 15px;
  }
  .sidebar-first aside {padding-top: 0;width: 100%;position: absolute;top: 23%; display: none;}
  .sidebar-first #content {padding-top: 0;}
  #views-exposed-form-faceted-products-page .form-item-sort-bef-combine label, .views-widget-per-page label {
    font-size: 22px;
  }
  .field-name-body {font-size: 22px;line-height: 22px;}
  .pager {font-size: 20px;}
  div.messages {position: relative;padding-right: 2.5em;}
  .ok, .messages--status {font-size: 30px;line-height: 1;text-align: center;}
  .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-savings .price-amount {display: none;}
  .commerce-price-savings-formatter-label-row .commerce-price-savings-formatter-savings .price-label {display: none;}
  #block-menu-menu-tag-menu li a {font-size: 22px;}
  .view-commerce-cart-form td.views-field-line-item-title .itemtitle {height: 96px;overflow: hidden;}
  .view-commerce-cart-form td.views-field-field-product-image {padding-right: 0px;}
  #block-superfish-1 .block-content select {background: #b3699d;color: white;text-indent: 6%;width: 268px;box-shadow: 3px 3px 7px 0 #b3699d, 0 -3px 0 0 #b3699d inset;text-shadow: 0 -2px 0 rgba(0, 0, 0, 0.3);font-size: 21px;border: 1px solid #ccc;height: 34px;border-radius: 9px;-webkit-appearance: none;-moz-appearance: none;appearance: none;}
  .view-commerce-cart-form td.views-field-commerce-unit-price, .view-commerce-cart-form td.views-field-commerce-total, .view-commerce-cart-form td.views-field-commerce-shipping-price {text-align: left;padding-left: 0px;}
  .view-commerce-cart-form .views-field-edit-quantity {min-width: 87px;padding-left: 0;padding-right: 4px;text-align: center;}
  form th {text-align: center;}
  .view-commerce-cart-form td.views-field-commerce-unit-price .commerce-price-savings-formatter-price .price-amount, .view-commerce-cart-form td.views-field-commerce-total .commerce-price-savings-formatter-price .price-amount, .view-commerce-cart-form td.views-field-commerce-shipping-price {position: inherit;}
  .sidebar-first #content {float: none;}
  .view-commerce-cart-form td.views-field-commerce-unit-price, .view-commerce-cart-form td.views-field-commerce-total, .view-commerce-cart-form td.views-field-commerce-shipping-price {text-align: center;padding-left: 0px;}
  .view-commerce-cart-form th.views-field-commerce-unit-price, .view-commerce-cart-form th.views-field-commerce-total, .view-commerce-cart-form th.views-field-commerce-shipping-price {text-align: center;}
  .view-commerce-cart-form td.views-field-commerce-unit-price .commerce-price-savings-formatter-prices tr td, .view-commerce-cart-form td.views-field-commerce-total .commerce-price-savings-formatter-prices tr td {text-align: center;margin-top: -14%;}
  .field-name-body img {margin-right: 0;}
  #block-views-productpage-block-1 .views-field-body,
  #block-views-productpage-block-1 .views-field-body .field-content span{font-size: 24px;font-family: verdana,geneva,sans-serif;line-height: 40px;}
  #block-views-productpage-block-1 .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-price .price-amount {
    position: relative;
  }
  #views-exposed-form-faceted-products-page .form-item-sort-bef-combine select{
    margin: 0;
  }
  #block-views-productpage-block-1 .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-list .price-amount {
    font-size: 27px;
    color: red;
    font-weight: 400;
    background: none;
    position: static;}
  table.commerce-price-savings-formatter-prices,
  table.commerce-price-savings-formatter-prices>tbody,
  table.commerce-price-savings-formatter-prices>tbody>tr{
    display: block;
    width: 100%;
  }
  table.commerce-price-savings-formatter-prices>tbody>tr{
    overflow: hidden;
  }
  #block-views-productpage-block-1 .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-price  {
    float: right;
    position: relative;
    width: auto;
  }
  #block-commerce-cart-cart .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-list {top: 85px;}
  .commerce-line-item-views-form .commerce-line-item-actions {text-align: right;margin-right: 4%;}
  .line-item-summary {text-align: right;margin-bottom: 1em;margin-right: 4%;}
  #block-commerce-cart-cart .links.inline li.line-item-summary-view-cart a {font-size:26px;}
  #block-commerce-cart-cart .links.inline li.line-item-summary-checkout a {font-size:26px;}
  .view-blog .views-field-nothing {text-align: right;margin-right: 3%;}
  .views-exposed-form .form-item, .views-exposed-form .form-submit {margin-top: 0;margin-bottom: 0;}
  #block-views-productpage-block-1 .views-field-sku .field-content {font-size: 22px;color: #999;}
  #block-views-productpage-block-1 .views-field-field-material li {font-size: 22px;}
  #block-views-productpage-block-1 .views-field-field-color-term .views-label, #block-views-productpage-block-1 .views-field-field-size-term .views-label, #block-views-productpage-block-1 .views-field-field-material .views-label, #block-views-productpage-block-1 .views-field-body .views-label, #block-views-productpage-block-1 .views-field-field-sizes .views-label {font-size: 22px;}
  #block-views-productpage-block-1 .views-field-sku .views-label {font-size: 22px;}
  #views-exposed-form-faceted-products-page .form-item-sort-bef-combine select {font-size: 18px;}
  .field-label-inline .field-label, .field-label-inline .field-items {font-size: 25px;float: left;}
  .field-name-field-tags .field-label {margin-right: 8px;font-size: 25px;}
  .responsive-menus.responsified {margin-left: 1%;}
  .responsive-menus.responsified span.toggler {
    width: 80px;
    padding: 5px 0 6px;
    background: #76b3dc;
    font-size: 23px;
    text-align: center;
    color: #fff;
    box-shadow: 0 0 2px #000;
  }
  .productlabeltype-1 {
    width: 57px;height: 22px;background: url(http://myfancycraft.com/sites/all/themes/myfancy/images/labelnew1.png) no-repeat 0px 0px;}
  .view-filters {
    padding: 0 10px;
    clear: both;
  }
  #block-views-productpage-block-1 .views-field-field-color-term{
    width: 27%;
    display: inline-block;
  }
  #block-views-productpage-block-1 .views-field-field-material {
    width: 27%;
    display: inline-block;
    padding-bottom: 0;
    margin-top: 10px;
  }
  #block-views-productpage-block-1 .views-field-commerce-shipping-price{
    position: static;
    display: inline-block;
    width: 71%;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 10px 0;
  }
  #block-views-productpage-block-1 .views-field-php,
  #block-views-productpage-block-1 .views-field-field-production-time{
    position: static;
    width: 65%;
    display: inline-block;
    float: right;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 10px;
  }
}
@media (max-width: 950px){
  #block-superfish-1{
    display: none;
  }
  #block-flexnav-flexnav-1 {
    display: block;
  }
  .preheader {
    position: relative;
  }
  .front .preheader,
  .front.nottop .preheader,
  .not-front .preheader{
    height: auto;
    background: none;
  }
  .block {

  }
  #block-menu-block-3 {
    position: static;
  }
  #block-block-5,
  #block-commerce-cart-cart {
    top: 34px;
    right: 7px;
  }
  #block-menu-block-3 {
    display: inline-block;
    margin-left: 28px;
  }
  #block-menu-block-3 li a {
    color: #bb80b8;
    font-size: 24px;
    font-weight: 700;
  }
  #block-flexnav-flexnav-1:before{
    content: '';
    clear:both;
    display: block;
  }
  #block-block-5 {
    font-size: 20px;
  }
  .flexslider .views-field-field-add-button {
    top: 60px;
  }
  .not-front #page {
    margin-top: 50px;
  }
  #footer #block-menu-block-1 li {
    display: block;
  }
}
@media (max-width: 900px){
  #footer #block-menu-block-1 ul#block-menu-menu-tag-menu {
    width:200px;
  }
  #footer #block-menu-block-1 ul#block-menu-menu-tag-menu  li{
    margin-bottom: 10px;
  }
  .view-productslistwithoutfilter .views-row,
  .view-search .views-row,
  .view-faceted-products .views-row{
    width: 43%;
  }
}
@media (max-width: 768px){
  .view-commerce-cart-form td.views-field-commerce-unit-price .commerce-price-savings-formatter-prices tr td,
  .view-commerce-cart-form td.views-field-commerce-total .commerce-price-savings-formatter-prices tr td {
    margin-top: -3px;
  }
  .commerce-price-savings-formatter-prices tr.odd, .commerce-price-savings-formatter-prices tr.even{
    text-align: center;
  }
  .commerce-price-savings-formatter-prices tr td, .commerce-price-savings-formatter-price .price-amount{
    display: inline-block;
  }
  table.commerce-price-savings-formatter-prices td{
    width: auto;
  }
  .view-productslistwithoutfilter .views-field-add-to-cart-form input[type="submit"],
  .view-search .views-field-add-to-cart-form input[type="submit"],
  .view-faceted-products .views-field-add-to-cart-form input[type="submit"]{
    top: 0;
  }
  .views-exposed-widgets {
    margin-bottom: 0;
  }
  #edit-sort-bef-combine-wrapper{
    width: 57%;
    text-align: right;
  }
  .views-exposed-form .views-widget-per-page{
    width: 43%;
  }
  #views-exposed-form-faceted-products-page .form-item-sort-bef-combine label,
  .views-widget-per-page label{
    font-weight: normal;
    font-size: 17px;
  }
  #views-exposed-form-faceted-products-page .form-item-sort-bef-combine select{
    padding: 1px;
  }
  #main {
    padding: 0 10px;
  }
  .breadcrumb {
    margin: 0;
  }
  .not-front #page {
    margin-top: 25px;
  }
  .responsive-toggled #superfish-2{
    margin-top: 1px;
    display: inline-block;
  }
  #superfish-2.sf-menu.menu > li > span{
    color: #b3699d;
  }
  #superfish-2.sf-menu.menu li ul li a {
    color: #4d94c0;
  }
  #superfish-2.sf-menu.menu li ul li{
    width: 7em !important;
  }
  #block-menu-block-3 li a {
    font-size: 23px;
  }
  #block-superfish-1 {
    width: 175px;
    display: none;
  }
  #block-block-5,
  #block-commerce-cart-cart{
    right:5px;
  }
  .view-header{
    top:0px;
  }
  .block-title, h1, .page-search h1, h2 {
    font-size: 27px;
    line-height: 47px;
    margin-bottom: 15px;
  }

  .ok, .messages--status {
    font-size: 20px;
  }

  .view-productslistwithoutfilter .views-row .views-field-title a,
  .view-search .views-row .views-field-title a,
  .view-faceted-products .views-row .views-field-title a {
    font-size: 20px;
  }

  .view-productslistwithoutfilter .views-row, .view-search .views-row, .view-faceted-products .views-row {
    width: 50%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px 10px 0;
    float: left;
  }

  .view-productslistwithoutfilter .views-row,
  .view-search .views-row,
  .view-faceted-products .views-row,
  .view-productslistwithoutfilter .views-row:nth-of-type(4n),
  .view-search .views-row:nth-of-type(4n),
  .view-faceted-products .views-row:nth-of-type(3n) {
    margin: 0;
  }

  .view-productslistwithoutfilter .views-row .views-field-title,
  .view-search .views-row .views-field-title,
  .view-faceted-products .views-row .views-field-title {
    margin-bottom: 10px;
  }

  .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-list {
    position: static;
    padding: 0;
  }

  .view-productslistwithoutfilter .views-field-commerce-price,
  .view-search .views-field-commerce-price,
  .view-faceted-products .views-field-commerce-price {
    margin-top: 0;
  }

  .productlabeltype-1 {
    top: 15px;
    right: 8px;
  }

  .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-list .price-amount,
  .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-price .price-amount {
    margin: 0;
    padding: 0;
    //font-size: 22px;
    line-height: 1;
  }
  .view-productslistwithoutfilter .views-field-add-to-cart-form input[type="submit"],
  .view-search .views-field-add-to-cart-form input[type="submit"],
  .view-faceted-products .views-field-add-to-cart-form input[type="submit"] {
    margin-top: 10px;
    right: 0;
  }
  #block-views-productpage-block-1 .views-label{
    width: auto;
  }
  .view-productslistwithoutfilter .views-row, .view-search .views-row, .view-faceted-products .views-row{
    height: auto;
  }
  table.commerce-price-savings-formatter-prices {
    width: 100%;
  }
  .region-footer2 {
    height: auto;
    padding: 30px 0;
  }

  .view-deals .views-row, .view-deals .views-row.views-row-1, .view-deals .views-row.views-row-3 {
    display: inline-block;
  }

  table.commerce-price-savings-formatter-prices {
    margin: 0;
  }

  #block-views-deals-block {
    font-size: 0;
    line-height: 1;
    text-align: center;
  }

  .flexslider .views-field-field-add-button {
    top: 20px;
  }

  .flexslider .slides img {
    min-height: 200px;
    max-height: 200px;
  }
  .flexslider .views-field-field-add-button {
    top: -34px;
  }

  .jcarousel-skin-tango .jcarousel-clip-horizontal {
    height: 52px;
  }

  #footer #block-menu-block-1 {
    left: auto;
    width: 100%;
    transform: translate(0, 0);
  }
  .prefooter {
    top: 0;
    padding: 30px 0;
    height: auto;
  }

  #footer #block-menu-block-1 li {
    padding: 0 20px;
  }
  #footer #block-menu-block-1 li a {
    font-size: 17px;
  }
  #footer #block-block-1,
  #footer #block-block-2 {
    padding: 0 10px;
  }
  #footer #block-block-1,
  #footer #block-block-3 {
    font-size: 14px;
  }
  #footer #block-block-3 {
    top: 30px;
    right: 10px;
  }
  #footer #block-menu-block-1 li {
    display: inline-block;
  }
  #superfish-2.sf-menu.menu>li{
    margin: 0 9px;
  }
  #superfish-2.sf-menu.menu>li:first-child{
    margin-left: 0;
  }
  #superfish-2.sf-menu.menu>li:last-child{
    margin-right: 0;
  }
}
@media (max-width: 635px){
  #sh_button {
    display: none;
  }
  .header__logo-image {
    width: 200px;
  }
  #block-block-5{
    padding-left: 40px;
  }
  #block-commerce-cart-cart {
    width: 165px;
  }
  #block-block-5, #block-commerce-cart-cart {
    top: 64px;
  }
  #block-menu-block-3 {
    margin-left: 32px;
  }
  .flexslider .views-field-field-add-button {
    top: 0;
  }
  #block-views-productpage-block,
  .jcarousel-skin-tango .jcarousel-container-horizontal,
  #block-views-productpage-block-1,
  .node-type-product-display #block-block-13,
  .jcarousel-skin-tango .jcarousel-clip-horizontal{
    width: auto;
  }
  .view-productpage img{
    border: 0;
  }
  .node-type-product-display #block-block-13 {
    left: auto;
  }
  .jcarousel-skin-tango .jcarousel-container-horizontal{
    width: auto;
  }
  .jcarousel-skin-tango .jcarousel-clip-horizontal {
    height: auto;
  }
  .views-field-php span.between{
    display: block;
  }
}
@media (max-width: 495px){
  #block-views-productpage-block-1 .views-field-add-to-cart-form{
    padding-left: 0;
  }
  #block-views-productpage-block-1 input[type="submit"]{
    background-position: 10px 11px;
    padding-left: 55px;
    width: 185px;
  }
}
@media (max-width: 420px) {
  .shopping-cart table,
  .shopping-cart tbody,
  .shopping-cart tr{
    display: block;
  }
  .shopping-cart td{
    display: inline-block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .shopping-cart.view-commerce-cart-form tr{
    padding-bottom: 10px;
  }
  .shopping-cart .views-field-line-item-title{
    width: 55%;
  }
  .shopping-cart .views-field-field-product-image{
    width: 40%;
  }
  .shopping-cart.view-commerce-cart-form td.views-field-field-product-image img{
    margin-bottom: 0;
  }
  .shopping-cart .views-field-commerce-shipping-price:before,
  .shopping-cart .views-field-commerce-unit-price:before{
    display: block;
    font-size: 18px;
    line-height: 1.2em;
    color: #333333;
    font-weight: 700;
    text-align: center;
    font-family: Calibri, Verdana, Tahoma;
  }
  .shopping-cart .views-field-commerce-shipping-price:before{
    content: "Shipping";
    margin-bottom: 8px;
  }
  input[type="submit"], a.button, .checkout-buttons .checkout-cancel, .checkout-buttons .checkout-back{
    font-size: 16px;
  }
  #commerce-checkout-form-checkout fieldset, #customer-profile-shipping-ajax-wrapper, #customer-profile-billing-ajax-wrapper{
    padding-right: 0;
  }
  #block-block-13 .block-content > p {
    float: none;
    margin-right: 0;
    margin-bottom: 2px;
    text-align: center;
  }
  #block-block-13 .block-content{
    text-align: center;
  }
  #block-block-13 .block-content > .fb_iframe_widget {
    top: 0;
  }
  .shopping-cart .views-field-commerce-unit-price:before{
    content: "Price";
    margin-bottom: 4px;
  }
  .shopping-cart .views-field-commerce-shipping-price,
  .shopping-cart .views-field-commerce-unit-price{
    width: 48%;
  }
  .shopping-cart .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-list .price-amount{
    display: none;
  }
  .shopping-cart .views-field-edit-quantity{
    text-align: right;
    width: 65%;
  }
  .shopping-cart .views-field-edit-delete{
    width: 30%;
    text-align: right;
  }
  .commerce-line-item-views-form .views-table thead{
    display: none;
  }
  .page-checkout{
    overflow-x: hidden;
  }
  .block-content .menu-button, .block-content .flexnav li a,
  .block-content .flexnav li span, .block-content .flexnav li ul li a, .block-content .flexnav ul li ul li a {
    font-size: 18px;
  }
  .view-productslistwithoutfilter .views-row .views-field-title a, .view-search .views-row .views-field-title a, .view-faceted-products .views-row .views-field-title a {
    font-size: 18px;
  }
  .view-deals .views-row,
  .view-deals .views-row.views-row-1, .view-deals .views-row.views-row-3{
    max-width: 80%;
    margin: 5px 0;
  }
  .field-name-field-blog-entry-image,
  .view-blog .views-field-field-blog-entry-image{
    width: 100%;
    float: none;
    text-align: center;
  }
  .view-blog .views-field-title{
    text-align: center;
  }
  #superfish-2{
    display: none;
    box-shadow: 0 0 2px #000;
  }
  .responsive-menus.responsified.responsive-toggled.absolute{
    text-align: left;
  }
  #superfish-2.sf-menu.menu li {
    margin: 0;
    border-bottom: 1px solid #cccccc;
  }
  #superfish-2.sf-menu.menu li:last-child{
    border-bottom: 0;
  }
  #superfish-2.sf-menu.menu li:hover ul{
    top: 0;
  }
  #superfish-2.sf-menu.menu li ul{
    top: -99999em;
    left: 10.69em !important;
    padding: 0;
  }
  #superfish-2.sf-menu.menu li ul li {
    padding: 7px 10px;
  }
  .views-exposed-form .views-widget-per-page {
    width: 48%;
  }
  #edit-sort-bef-combine-wrapper {
    width: 52%;
  }
  .block__title {
    margin-top: 0;
  }
  #block-menu-block-3 li a,
  #block-block-5 {
    font-size: 16px;
  }
  #block-views-productpage-block-1 .views-field-php{
    top: 180px;
  }
  #block-views-productpage-block-1 .views-field-field-production-time .views-label-field-production-time{
    float: none;
    display: inline;
  }
  #block-commerce-cart-cart .block-content {
    right: 0;
    width: 286px;
  }

  #block-block-5, #block-commerce-cart-cart {
    top: 69px;
  }

  .header__logo {

  }
  .header__logo {

  }

  .header__logo-image {

  }

  #block-menu-block-3 {
    margin-left: 25px;
  }
  .menu-button,
  .flexnav li a,
  .flexnav li span {
    padding: .5em;
    font-size: 22px;
  }

  .menu-button .touch-button,
  .flexnav .touch-button {
    height: 42px;
  }

  .menu-button .touch-button .navicon {
    top: 31%;
    font-size: 20px;
  }

  /*shopping-cart*/
  #block-commerce-cart-cart .block-content .views-field-line-item-title {
    max-height: 80px;
    height: 80px;
    overflow: hidden;
    display: inline-block;
  }

  #block-commerce-cart-cart .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-price,
  #block-commerce-cart-cart .commerce-price-savings-formatter-price {
    top: 95px;
  }

  #block-commerce-cart-cart .commerce-price-savings-formatter-price {
    right: 260px;
  }

  #block-commerce-cart-cart td.views-field.views-field-commerce-shipping-price .shippingpricedropdowncart {
    top: 112px;
  }

  #block-commerce-cart-cart .block-content .views-field-field-product-image {
    padding-bottom: 40px;
  }

  #block-commerce-cart-cart .block-content .views-field-quantity {
    margin-top: auto;
    position: relative;
    left: -127px;
    top: 95px;
    padding: 0;
  }

  .line-item-summary {
    margin-right: 4%;
  }

  #block-commerce-cart-cart .links.inline li.line-item-summary-view-cart,
  #block-commerce-cart-cart .links.inline li.line-item-summary-checkout {
    width: 45%;
  }

  #block-commerce-cart-cart .links.inline li.line-item-summary-view-cart a,
  #block-commerce-cart-cart .links.inline li.line-item-summary-checkout a {
    width: 100%;
  }

  .line-item-summary {
    margin-right: 0;
    margin-top: 10px;
  }
  #block-views-productpage-block-1 input[type="submit"]{
    font-size: 18px;
    width: 205px;
    padding-left: 55px;
    text-align: center;
    background-position: 17px 11px;
  }
  .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-list {
    margin-right: 5px;
  }
  #commerce-checkout-form-checkout fieldset.checkout-buttons, fieldset.checkout-buttons{
    margin-bottom: 20px;
  }
  #superfish-2.sf-menu>li {
    width: 10em;
    float: none;
    padding: 6px 5px;
    margin-top: 10px;
  }
  .page-checkout #content fieldset input.form-text {
    width: 290px;
  }
  .ok, .messages--status {
    font-size: 18px;
  }
  #footer #block-menu-block-1 li {
    padding: 0 8px;
  }
  /*catalog*/
  #views-exposed-form-faceted-products-page .form-item-sort-bef-combine label, .views-widget-per-page label {
    font-size: 14px;
    margin-left: 0;
    margin-right: 5px;
  }

  #views-exposed-form-faceted-products-page .views-widget-per-page select,
  #views-exposed-form-faceted-products-page .form-item-sort-bef-combine select {
    font-size: 13px;
    margin: 0;
    padding: 0;
  }
  /*product-page*/
  .block-title, h1, .page-search h1, h2 {
    font-size: 18px;
    line-height: 1.3em;
  }
  .jcarousel-skin-tango .jcarousel-clip-horizontal {
    height:auto;
    max-height: 80px;
  }
  .breadcrumb {
    margin-left: 0;
  }
  #block-views-productpage-block-1 .views-field-nothing {
    font-size: 18px;
    margin-bottom: 55px;
  }
  #block-views-productpage-block-1 .views-field-field-material,
  #block-views-productpage-block-1 .views-field-sku,
  #block-views-productpage-block-1 .views-field-field-color-term{
    width: 36%;
  }
  #block-views-productpage-block-1 .views-field-commerce-price,
  #block-views-productpage-block-1 .views-field-add-to-cart-form,
  #block-views-productpage-block-1 .views-field-field-production-time,
  #block-views-productpage-block-1 .views-field-commerce-shipping-price{
    width: 62%;
  }
  #block-views-productpage-block-1 .views-field-php{
    width: 100%;
    margin-top: 10px;
  }
  #block-views-productpage-block-1 .views-field-commerce-shipping-price {
    font-size: 18px;
  }
  #block-views-productpage-block-1 .form-item-quantity{
    position: absolute;
    right: 0;
    top: 75px;
  }
  #block-views-productpage-block-1 .views-field-sku .views-label, #block-views-productpage-block-1 .views-field-field-color-term .views-label, #block-views-productpage-block-1 .views-field-field-size-term .views-label, #block-views-productpage-block-1 .views-field-field-material .views-label, #block-views-productpage-block-1 .views-field-body .views-label,
  #block-views-productpage-block-1 .views-field-field-sizes .views-label,
  #block-views-productpage-block-1 .views-field-field-delivery-time, #block-views-productpage-block-1 .views-field-php,
  #block-views-productpage-block-1 .views-field-field-production-time{
    font-size: 18px;
  }
  #block-views-productpage-block-1 .views-field-field-length .views-label,
  #block-views-productpage-block-1 .views-field-field-width .views-label,
  #block-views-productpage-block-1 .views-field-field-height .views-label,
  #block-views-productpage-block-1 .views-field-field-weight .views-label,
  #block-views-productpage-block-1 .views-field-field-material li,
  #block-views-productpage-block-1 .views-field-sku .field-content,
  #block-views-productpage-block-1 .form-item-quantity label{
    font-size: 18px;
  }
  #block-views-productpage-block-1 .views-field-commerce-price {
    margin-top: 50px;
  }
  #block-views-productpage-block-1 .commerce-price-savings-formatter-price-row .commerce-price-savings-formatter-price .price-amount {
    font-size: 36px;
  }
  #block-views-productpage-block-1 .views-field-commerce-price .field-content{
    width: 185px;
  }
  #block-views-productpage-block-1 .field-group-expression{
    font-size: 14px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  #block-views-productpage-block-1 .views-field-field-weight {
    margin-right: 0;
  }
  .views-field-field-length .views-label{
    width: auto;
  }
  #block-views-productpage-block-1 .views-field-body,
  #block-views-productpage-block-1 .views-field-body .field-content span {
    font-size: 18px;
    line-height: 28px;
    margin-top: 0;
  }
  .view-productslistwithoutfilter .views-row .views-field-title,
  .view-search .views-row .views-field-title,
  .view-faceted-products .views-row .views-field-title {
    max-height: 76px;
  }
  .flexslider .views-field-field-add-button {
    top: -54px;
  }
  .flex-direction-nav{
    top: -85px;
  }
  .views-exposed-form .form-item, .views-exposed-form .form-submit {
    float: none;
  }
  .page-checkout .checkout-buttons .form-submit{
    position: static;
    width: 95%;
    padding: 0;
    margin: 5px auto;
    display: block;
  }
  .view-commerce-cart-form td.views-field-commerce-unit-price .commerce-price-savings-formatter-prices tr td, .view-commerce-cart-form td.views-field-commerce-total .commerce-price-savings-formatter-prices tr td{
    margin-top: 0;
  }
  .commerce-line-item-views-form .commerce-line-item-actions{
    margin-right: 0;
    margin-bottom: 20px;
  }
  .commerce-line-item-actions #edit-submit,
  .commerce-line-item-actions #edit-checkout{
    display: block;
    width: 95%;
    margin: 5px auto;
  }
  #main {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .not-logged-in #commerce-checkout-form-checkout fieldset.commerce_fieldgroup_pane__group_comment,
  #commerce-checkout-form-checkout fieldset,
  #customer-profile-shipping-ajax-wrapper,
  #customer-profile-billing-ajax-wrapper,
  #commerce-checkout-form-checkout fieldset.commerce_fieldgroup_pane__group_comment{
    width: auto;
    right: 0;
  }
  #commerce-checkout-form-checkout fieldset, #customer-profile-shipping-ajax-wrapper,
  #customer-profile-billing-ajax-wrapper,
  #commerce-checkout-form-checkout fieldset.commerce_fieldgroup_pane__group_comment{
    width: 290px;
    right:0;
  }
  #block-views-productpage-block-1 .views-field-expression, #block-views-productpage-block-1 .views-field-expression-1, #block-views-productpage-block-1 .views-field-expression-2, #block-views-productpage-block-1 .views-field-expression-3 {
    width: 32%;
    padding: 2px;
    margin-right: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .views-field-php span.between {
    font-size: 16px;
  }
}
@media (max-width: 360px) {
  #block-views-productpage-block-1 .views-field-field-production-time .field-content{
    display: inline;
  }
  .views-exposed-form .views-widget-per-page,
  #edit-sort-bef-combine-wrapper{
    width: auto;
    float: none;
    text-align: left;
  }
  .views-widget-per-page{
    margin-top: 10px;
  }
  .view-header{
    margin-bottom: 5px;
  }
}