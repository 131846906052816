.region-header2 {

  .flexslider {
    overflow: hidden;
  }

/* START FLEXSLIDER */
.flexslider .views-field-field-add-button{
  position: absolute;
  top: 150px;
  z-index: 10;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width:1170px;
}
.flexslider .views-field-field-add-button a{
  color: #fff;
  padding:10px 20px;
  border:2px solid #fff;
  border-radius:3px;
}
.flexslider .views-field-title{
  position: absolute;
  top: 210px;
  z-index: 10;
  color: #fff;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size:48px;
  line-height:58px;
  font-family:Georgia;
  font-weight:700;
  width:505px;
}
.flexslider .views-field-field-text-line{
  position: absolute;
  top: 360px;
  z-index: 10;
  color: #fff;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size:24px;
  line-height:26px;
  font-family:Georgia;
  font-weight:400;
}
.flexslider {
  margin: 0px;
  background: none;
  border: none;
  position: relative;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  zoom: 1;
}
.flexslider .slides img {
  width: 100%;
  display: block;
  min-width: initial;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
}
.flexslider .flex-direction-nav a, .flexslider .flex-direction-nav a{
  display:block;
}
.flexslider:hover .flex-prev,
.flexslider:hover .flex-next{
  opacity:1;
}
.flex-direction-nav {

  position: absolute;
  left: 0;
  right: 0;

  text-align: center;
  z-index: 10;
  top:50%;
}
.flex-direction-nav a{

  display:block;
  text-indent:-9999px;
  background-image: url(img/sprite.png);
  opacity: 0.3;
  width: 60px;
  height: 60px;
  transition: opacify 0.3s ease;
  &:hover {
   opacity: 1;
  }
}
.admin-menu .flex-direction-nav a{
  top:335px;

}
.flex-direction-nav a.flex-prev{
  @include sprite-position($arrow-left);
}
.flex-direction-nav a.flex-next{
  @include sprite-position($arrow-right);
}
.flex-direction-nav .flex-prev,
.flexslider:hover .flex-prev{
  left: 20px;
}
.flex-direction-nav .flex-next,
.flexslider:hover .flex-next{
  right: 20px;
}
.flex-direction-nav a:before{
  display:none;
}

/* END FLEXSLIDER */
}