.header__content,
.page,
.footer__content,
.region-content-1st,

.copyright {
  margin: 0 auto;
  @include desktop {
    max-width: 1200px;
  }
  @include x-wide {
    max-width: 1600px;
    .not-front & {
      max-width: 1200px;
    }
  }
}

.main-content {
  font-size: 0;
  box-sizing: border-box;
  padding: 0 15px;

  @include tablet {
    padding: 0 40px;
  }

}

.region-front-popular {
  font-size: $font-size-base;
  @include tablet {
    display: inline-block;
    vertical-align: top;
    width: 75%;
  }


}

.region-front-deal {
  font-size: $font-size-base;
  @include tablet {
  display: inline-block;
  vertical-align: top;
  width: 25%;

  }
}

.region-footer-left,
.region-footer,
.region-footer-right {
  box-sizing: border-box;
  text-align: center;

  @include tablet {
    text-align: left;
    display: inline-block;
    vertical-align: top;
    width: 33.33%;
  }
}