#flexnav_1 {
  padding: 0;
}

.view-faceted-products  {
  .views-exposed-form .views-exposed-widget {
    float: right;
    padding: 0;
    text-align: right;
    width: 50%;

    &.views-submit-button {
      display: none;
    }
  }

  .view-content {
    clear: both;
  }

}

.page__title {
  color:#b3699d;
  font-family:Georgia;
  font-size:32px;
  line-height: 45px;
  font-weight:700;
  text-align:center;
  position: relative;
  margin: 20px 0 40px;

  &::after {
    content:" ";
    position:absolute;
    width:182px;
    height:5px;
    top: 100%;
    background:#b3699d;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.block__title {
  .not-front & {


  font-family: Georgia;
  font-weight: 700;
  text-align: center;
  position: relative;
  margin-bottom: 45px;
  margin-top: -5px;
  font-size: 30px;
  line-height: 48px;
  color: #666;

    &::after {
      content: " ";
      position: absolute;
      width: 182px;
      top: 100%;
      background: #666;
      height: 4px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

    }
}
  aside .region-sidebar-first & {
    font-size: 18px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 0;
    font-family: calibri;
    color: #b3699d;
    text-align: center;

    &::after {
      content: none;
    }
  }


}

.views-field-add-to-cart-form {

  .view-productpage & {
    @include w1100 {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    }
  }

  .view-faceted-products & {
    @include desktop {
      float: right;
      top: -30px;
      position: relative;
    }

  }



}

#block-views-productpage-block-1 .views-field-commerce-shipping-price {
  display: block;
  @include tablet {
    display: inline-block;
  }
}

#block-views-productpage-block-1 .views-field-field-production-time,
#block-views-productpage-block-1 .views-field-php {
  float: left;
  @include tablet {
    float: right;
  }
}

.option-button-wrapper {
  float: none;
  clear: both;
  text-align: right;
}

.option-button {
  margin: 5px;
@include desktop {
  display: inline-block;

}
}

.add-cart-message-wrapper {
  top: 150px;
}