
.mf-category {
  display: inline-block;
  vertical-align: top;
  float: left;
  font-size: 16px;
  font-family: $font-family-header;
  color: $color-font;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin: 5px 0.5%;
  border: 1px solid #959595;
  overflow: hidden;

  @include tablet {
    width: 48%;
  }


  @include desktop {

    width: calc(1/3*100% - (1 - 1/3)*15px);
  }

  @include x-wide {
    width: calc(1/4*100% - (1 - 1/4)*15px);
  }

  a {
    display: block;
  }



  &:nth-child(1),
  &:nth-child(8) {
    width: 100%;
    height: 200px;
    @include tablet {
      width: 48.5%;
      height: 300px;
    }
    @include desktop {
      width: 48.5%;
      height: 300px;
    }
  }
  &:nth-child(2),
  &:nth-child(6),
  &:nth-child(7) {
    width: 48.5%;
    height: 200px;
    @include tablet {
      width: 23.75%;
      height: 300px;
    }
    @include desktop {
      width: 23.75%;
      height: 300px;
    }
  }
  &:nth-child(3) {
    width: 48.5%;
    height: 200px;
    @include tablet {
      width: 23.75%;
      height: 145px;
    }
    @include desktop {
      width: 23.75%;
      height: 145px;
    }
  }
  &:nth-child(4),
  &:nth-child(5) {
    width: 100%;
    height: 200px;
    @include tablet {
      width: 11.375%;
      height: 145px;
    }
    @include desktop {
      width: 11.375%;
      height: 145px;
    }
  }

}

.mf-category__image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  img {
    width: 100%;
    height: auto;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.mf-category__title a {
  font-family: $font-family-sub;
  font-size: 20px;
  text-align: center;
  color: $color-font;
  padding: 5px 0 10px;
  line-height: 1.2;
}

.mf-product__category {
  a {
    color: inherit;
    text-decoration: none;
    font-size: 0.75em;
    display: inline-block;
    margin: 10px 0;
  }
}

