
.mf-product {
  display: block;
  font-size: 16px;
  font-family: $font-family-header;
  color: $color-font;
  box-sizing: border-box;
  padding: 10px 10px 40px;
  margin-bottom: 20px;
  border: 1px solid #959595;
  text-align: left;
  width: 48%;
  position: relative;


  @include desktop {

    width: calc(1/3*100% - (1 - 1/3)*20px);
  }

  @include x-wide {
    width: calc(1/4*100% - (1 - 1/4)*20px);
  }

  table {
    margin: 0;
  }

}

.mf-product__image {
  img {
    width: 100%;
    height: auto;
  }
}

.mf-product__title {
  a {
    color: inherit;
    text-decoration: none;
    font-size: 0.75em;
    display: inline-block;
    margin: 10px 0 0;
  }
}

.mf-product__price {
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
  position: absolute;
  bottom: 10px;
  left: 10px;

  .commerce-price-savings-formatter-price .price-amount {
    font-size: 16px;
    line-height:28px;
    @include desktop {
      line-height:18px;
      font-size: 18px;
    }
  }

}

.mf-product__cart {
  display: inline-block;
  vertical-align: top;
  position: absolute;
  bottom: 8px;
  right: 10px;

  @include w1100 {
    bottom: 12px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    background-image: url(img/sprite.png);
    @include sprite-position($cart);
    width: 22px;
    height: 18px;
    pointer-events: none;
  }

  input[type=submit] {
    display: inline-block;
    background: none;
    border: 0;
    padding-right: 30px;
    padding-left: 5px;
    color: $color-cart;
    font-size: 0;
    cursor: pointer;
    line-height: 24px;
    height: auto;

    @include tablet {
      font-size: 13px;

    }

    @include desktop {
      font-size: 15px;
      line-height: 20px;
    }
  }
}

