.s-shop {

}

.s-shop__link {
  position: relative;
  font-size: 10px;
  line-height: 1;
  padding: 13px 0 4px;
  color: black;
  display: inline-block;
  vertical-align: top;
  opacity: 0.8;
  transition: 0.3s ease;

  &:hover {
    opacity: 1;
  }

  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-image: url(img/sprite.png);
    background-repeat: no-repeat;
    width: 40px;
    height: 16px;

  }

  &--ebay::before {
    @include sprite-position($ebay);
  }

  &--amazon::before {
    @include sprite-position($amazon);
  }


}

.shop__link {
  display: block;
  height: 25px;
  background: white;
  opacity: 0.2;
  font-size: 0;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 0;
  }
}
