// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$amazon-name: 'amazon';
$amazon-x: 84px;
$amazon-y: 143px;
$amazon-offset-x: -84px;
$amazon-offset-y: -143px;
$amazon-width: 40px;
$amazon-height: 16px;
$amazon-total-width: 232px;
$amazon-total-height: 183px;
$amazon-image: 'sprite.png';
$amazon: (84px, 143px, -84px, -143px, 40px, 16px, 232px, 183px, 'sprite.png', 'amazon', );
$arrow-down-name: 'arrow-down';
$arrow-down-x: 214px;
$arrow-down-y: 68px;
$arrow-down-offset-x: -214px;
$arrow-down-offset-y: -68px;
$arrow-down-width: 12px;
$arrow-down-height: 7px;
$arrow-down-total-width: 232px;
$arrow-down-total-height: 183px;
$arrow-down-image: 'sprite.png';
$arrow-down: (214px, 68px, -214px, -68px, 12px, 7px, 232px, 183px, 'sprite.png', 'arrow-down', );
$arrow-left-small-name: 'arrow-left-small';
$arrow-left-small-x: 214px;
$arrow-left-small-y: 34px;
$arrow-left-small-offset-x: -214px;
$arrow-left-small-offset-y: -34px;
$arrow-left-small-width: 18px;
$arrow-left-small-height: 32px;
$arrow-left-small-total-width: 232px;
$arrow-left-small-total-height: 183px;
$arrow-left-small-image: 'sprite.png';
$arrow-left-small: (214px, 34px, -214px, -34px, 18px, 32px, 232px, 183px, 'sprite.png', 'arrow-left-small', );
$arrow-left-name: 'arrow-left';
$arrow-left-x: 0px;
$arrow-left-y: 81px;
$arrow-left-offset-x: 0px;
$arrow-left-offset-y: -81px;
$arrow-left-width: 60px;
$arrow-left-height: 60px;
$arrow-left-total-width: 232px;
$arrow-left-total-height: 183px;
$arrow-left-image: 'sprite.png';
$arrow-left: (0px, 81px, 0px, -81px, 60px, 60px, 232px, 183px, 'sprite.png', 'arrow-left', );
$arrow-right-small-name: 'arrow-right-small';
$arrow-right-small-x: 214px;
$arrow-right-small-y: 0px;
$arrow-right-small-offset-x: -214px;
$arrow-right-small-offset-y: 0px;
$arrow-right-small-width: 18px;
$arrow-right-small-height: 32px;
$arrow-right-small-total-width: 232px;
$arrow-right-small-total-height: 183px;
$arrow-right-small-image: 'sprite.png';
$arrow-right-small: (214px, 0px, -214px, 0px, 18px, 32px, 232px, 183px, 'sprite.png', 'arrow-right-small', );
$arrow-right-name: 'arrow-right';
$arrow-right-x: 62px;
$arrow-right-y: 81px;
$arrow-right-offset-x: -62px;
$arrow-right-offset-y: -81px;
$arrow-right-width: 60px;
$arrow-right-height: 60px;
$arrow-right-total-width: 232px;
$arrow-right-total-height: 183px;
$arrow-right-image: 'sprite.png';
$arrow-right: (62px, 81px, -62px, -81px, 60px, 60px, 232px, 183px, 'sprite.png', 'arrow-right', );
$cart-name: 'cart';
$cart-x: 106px;
$cart-y: 161px;
$cart-offset-x: -106px;
$cart-offset-y: -161px;
$cart-width: 22px;
$cart-height: 18px;
$cart-total-width: 232px;
$cart-total-height: 183px;
$cart-image: 'sprite.png';
$cart: (106px, 161px, -106px, -161px, 22px, 18px, 232px, 183px, 'sprite.png', 'cart', );
$ebay-name: 'ebay';
$ebay-x: 124px;
$ebay-y: 123px;
$ebay-offset-x: -124px;
$ebay-offset-y: -123px;
$ebay-width: 40px;
$ebay-height: 16px;
$ebay-total-width: 232px;
$ebay-total-height: 183px;
$ebay-image: 'sprite.png';
$ebay: (124px, 123px, -124px, -123px, 40px, 16px, 232px, 183px, 'sprite.png', 'ebay', );
$fb-name: 'fb';
$fb-x: 124px;
$fb-y: 81px;
$fb-offset-x: -124px;
$fb-offset-y: -81px;
$fb-width: 40px;
$fb-height: 40px;
$fb-total-width: 232px;
$fb-total-height: 183px;
$fb-image: 'sprite.png';
$fb: (124px, 81px, -124px, -81px, 40px, 40px, 232px, 183px, 'sprite.png', 'fb', );
$gp-name: 'gp';
$gp-x: 42px;
$gp-y: 143px;
$gp-offset-x: -42px;
$gp-offset-y: -143px;
$gp-width: 40px;
$gp-height: 40px;
$gp-total-width: 232px;
$gp-total-height: 183px;
$gp-image: 'sprite.png';
$gp: (42px, 143px, -42px, -143px, 40px, 40px, 232px, 183px, 'sprite.png', 'gp', );
$in-name: 'in';
$in-x: 0px;
$in-y: 143px;
$in-offset-x: 0px;
$in-offset-y: -143px;
$in-width: 40px;
$in-height: 40px;
$in-total-width: 232px;
$in-total-height: 183px;
$in-image: 'sprite.png';
$in: (0px, 143px, 0px, -143px, 40px, 40px, 232px, 183px, 'sprite.png', 'in', );
$payment-name: 'payment';
$payment-x: 0px;
$payment-y: 0px;
$payment-offset-x: 0px;
$payment-offset-y: 0px;
$payment-width: 170px;
$payment-height: 79px;
$payment-total-width: 232px;
$payment-total-height: 183px;
$payment-image: 'sprite.png';
$payment: (0px, 0px, 0px, 0px, 170px, 79px, 232px, 183px, 'sprite.png', 'payment', );
$pi-name: 'pi';
$pi-x: 172px;
$pi-y: 0px;
$pi-offset-x: -172px;
$pi-offset-y: 0px;
$pi-width: 40px;
$pi-height: 40px;
$pi-total-width: 232px;
$pi-total-height: 183px;
$pi-image: 'sprite.png';
$pi: (172px, 0px, -172px, 0px, 40px, 40px, 232px, 183px, 'sprite.png', 'pi', );
$search-name: 'search';
$search-x: 84px;
$search-y: 161px;
$search-offset-x: -84px;
$search-offset-y: -161px;
$search-width: 20px;
$search-height: 20px;
$search-total-width: 232px;
$search-total-height: 183px;
$search-image: 'sprite.png';
$search: (84px, 161px, -84px, -161px, 20px, 20px, 232px, 183px, 'sprite.png', 'search', );
$tw-name: 'tw';
$tw-x: 172px;
$tw-y: 42px;
$tw-offset-x: -172px;
$tw-offset-y: -42px;
$tw-width: 40px;
$tw-height: 40px;
$tw-total-width: 232px;
$tw-total-height: 183px;
$tw-image: 'sprite.png';
$tw: (172px, 42px, -172px, -42px, 40px, 40px, 232px, 183px, 'sprite.png', 'tw', );
$yt-name: 'yt';
$yt-x: 172px;
$yt-y: 84px;
$yt-offset-x: -172px;
$yt-offset-y: -84px;
$yt-width: 40px;
$yt-height: 40px;
$yt-total-width: 232px;
$yt-total-height: 183px;
$yt-image: 'sprite.png';
$yt: (172px, 84px, -172px, -84px, 40px, 40px, 232px, 183px, 'sprite.png', 'yt', );
$spritesheet-width: 232px;
$spritesheet-height: 183px;
$spritesheet-image: 'sprite.png';
$spritesheet-sprites: ($amazon, $arrow-down, $arrow-left-small, $arrow-left, $arrow-right-small, $arrow-right, $cart, $ebay, $fb, $gp, $in, $payment, $pi, $search, $tw, $yt, );
$spritesheet: (232px, 183px, 'sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
