.region-navigation {
  .block {
    margin: 0;
  }
  .block-content {
    display: block;

  }
  .sf-menu {
    color: $color-font;
    display: flex;
    justify-content: space-between;

    li {
      &.sf-depth-1 {
        &::before {
          content:" ";
          background:url(../images/menutriangle.png) no-repeat 0 0;
          width: 24px;
          height: 22px;
          display: block;
          position: absolute;
          z-index: 999;
          top: 21px;
          left: 50%;
          margin-left: -12px;
          opacity: 0;
          transition: opacity 0.1s linear;
        }
      }
      > ul {
        left: 0;
        top: 54px;
      }
      &:hover {
        &::before {
          opacity: 1;
        }

      }

      a {
        position: relative;
        font-size: 17px;
        color: $color-font;



      }
    }
    > li {
      margin: 0 15px;
      > a {
        line-height: 2;
        padding-right: 20px;
        font-size: 1em;
        &::after {
          position: absolute;
          top: 12px;
          right: 0;
          content: '';
          display: block;
          background-image: url(img/sprite.png);
          @include sprite-position($arrow-down);
          width: 12px;
          height: 7px;
        }
      }
    }
  }
}
