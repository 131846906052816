.ajax-shopping-cart-teaser {
  position: relative;
  background: white;
  border: 1px solid $color-border;
  border-radius: 3px;
  font-weight: bold;
  font-size: 0.85em;
  padding: 5px 33px 5px 10px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 6px;
    top: 6px;
    background-image: url(img/sprite.png);
    @include sprite-position($cart);
    width: 22px;
    height: 18px;
    transition: right 0.3s ease;

  }

  .cart-product-quantity {
    float: none;
    display: inline-block;
    margin-right: 3px;
    a {
      color: $color-font;
    }
  }

  .cart-product-total {
    float: none;
    display: inline-block;
    margin-right: 0;
    padding-right: 10px;
    border-right: 1px solid $color-border;
  }

  .cart-button-link .quantity {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    font-size: 0;
  }



}

.commerce-add-to-cart {
  position: relative;
     .ajax-progress {
       position: absolute;
       bottom: 0;
       right: 0;
       left: 0;
       background: rgba(255,255,255,0.9);
       border-radius: 5px;
       box-shadow: 1px 1px 5px 0 grey;
       padding: 5px 10px;
       display: none;
     }
   }