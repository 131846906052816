@import "variables";
@import "sprite";




  @import "old";



@import "views--common";
@import "mf-product";
@import "mf-category";
@import "fonts";
@import "menu--top";
@import "region-navigation";
@import "region-front-trend";
@import "region-front-deal";
@import "layout";
@import "s-social";
@import "s-shop";
@import "slider-top";
@import "carousel";
@import "helper";
@import "search";
@import "link-login";
@import "cart";
@import "blocks";
@import "footer";
@import "header";


body {
  font-family: $font-family-common;
  color: $color-font;
  font-size: $font-size-base;

  .copyright {
    text-align: center;
    font-size: 0.75em;
    padding: 0 40px;
    @include tablet {
      text-align: left;
    }
  }

}
