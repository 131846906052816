#block-menu-block-4 {

  .menu {
    margin: 0;
  }

  .menu__item {
    padding: 0;
  }

  .menu__link {
    display: block;
    background: $color-button;
    padding: 5px 10px;
    color: white;
    border-radius: 3px;
    font-size: 0.85em;
  }
}