.header {
  background: $color-header;
  border-bottom: 1px solid $color-border;

}
.header__content {
  padding: 7px 10px;
  text-align: center;
  .block {
    margin-bottom: 0;
  }

  @include tablet {
    margin: 0 auto;
    font-size: 0;
    max-width: 1200px;
    .block {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }

  }
  @include desktop {
    .block {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;
    }
  }
}

.header__logo {
  display: block;
  float: none;
  text-align: center;

  @include tablet {
    display: inline-block;
    vertical-align: middle;
    width: 30%;
  }
  @include desktop {
    width: 18%;
  }

}



.region-header {
  text-align: center;
  margin-bottom: 10px;
  padding: 0 10px;
  box-sizing: border-box;

  @include tablet {
    display: inline-block;
    vertical-align: middle;
    width: 40%;
    font-size: 16px;
    margin-bottom: 0;

  }
  @include desktop {
    font-size: 0;
    width: 52%;
    .block {
      display: inline-block;
      font-size: 16px;
    }
  }



}

.region-header-right {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  .block {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 10px;
  }
  @include tablet {
    width: 30%;
    box-sizing: border-box;
    .block {
      display: block;
      padding-bottom: 0;
    }
  }
  @include desktop {
    width: 30%;
    .block {
    display: inline-block;
  }
  }

}
