.footer {
  background: $color-footer;
  border-top: 1px solid $color-border;
  padding: 30px 0 10px;



  .menu {
    column-count: 2;
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      a {
        color: $color-font;
        text-decoration: none;
        transition: 0.3s ease;

        &:hover {
          color: $color-cart;
       //   margin-left: 2px;
        }
      }
    }
  }
}

.footer__content {
  padding: 0 40px;
  @include tablet {
    display: flex;
    justify-content: space-between;

  }

}

.region-footer {
  text-align: center;
}
.region-footer-right {
  @include tablet {

    text-align: right;
  }
}
