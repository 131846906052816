.header {

  .menu {
    list-style: none;
    padding: 0;
    margin: 10px auto;
    text-align: center;
  }

  .menu__item {
    display: inline-block;
    padding: 0 5px;
    &:nth-child(n+4) {
      display: none;
    }
  }

  .menu__link {

  }
}