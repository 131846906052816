.region-front-carousel {
  padding: 0 15px;
}

.view-id-artisans.view-display-id-block {

  .flexslider {
    border: none;
    box-shadow: none;
  }

  .flex-viewport {
    padding: 0 40px;
  }

  font-size: 0;
  .slides {
    li {
      display: inline-block;
      vertical-align: top;
      text-align: center;
      font-size: 16px;

      @include tablet {
        width: 50%;
      }
      @include desktop {
        width: 33.33%;
      }

      .flex-caption a {
        color: $color-font;
        font-size: 20px;
        font-weight: bold;
      }

    }
  }

  .s-testimonial__photo {
    border-radius: 100%;
    border: 1px solid $color-border;
    overflow: hidden;
    height: 200px;
    width: 200px;
    margin: 0 auto;
  }

  .s-testimonial__title {
    text-align: center;
    margin: 10px 0 0;
    a {
      font-family: $font-family-header;
      font-size: 24px;
      color: $color-font;
    }
  }

  .s-testimonial__occupation {
    font-size: 18px;
  }

  .flexslider .flex-direction-nav {

    a {
      top:110px;
      display:block;
      text-indent:-9999px;
      background-image: url(img/sprite.png);
      opacity: 0.3;
      width: 18px;
      height: 32px;
      transition: opacify 0.3s ease;
      &:hover {

        opacity: 1;
      }
      &::before {
        content: none;
      }
    }
    .flex-prev {
      left: 0;
      @include sprite-position($arrow-left-small);
    }

    .flex-next {
      right: 0;
      @include sprite-position($arrow-right-small);
    }
  }
}