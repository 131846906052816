.view-id-productslistwithoutfilter.view-display-id-front  {
  .view-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.view-id-trending_categories.view-display-id-front {
  .view-content {
    font-size: 0;
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

