$tablet-width: 640px;
$desktop-width: 960px;
$wide-desktop-width: 1200px;
$extra-wide-desktop-width: 1600px;
$width_1100: 1100px;

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}
@mixin w1100 {
  @media (min-width: #{$width_1100}) {
    @content;
  }
}@mixin wide {
  @media (min-width: #{$wide-desktop-width}) {
    @content;
  }
}
@mixin x-wide {
  @media (min-width: #{$extra-wide-desktop-width}) {
    @content;
  }
}

$color-font: #252525;
$color-border: #acacac;
$color-header: #F4F4F4;
$color-button: #8d7a7b;
$color-footer: #f9f9f9;
$color-cart: #936591;
$font-size-base: 16px;
$font-size-title: 2.25em;
$font-family-header: Martel, Arial, Helvetica, sans-serif;
$font-family-sub: Noto, Arial, Helvetica, sans-serif;
$font-family-common: Arial, Helvetica, sans-serif;
