.block__title {
  font-family: $font-family-header;
  font-size: $font-size-title;
  text-align: center;
  margin: 45px 0;
  line-height: 1.1;
}

#block-block-17 { // Payment block
  background-image: url(img/sprite.png);
  @include sprite-position($payment);
  background-repeat: no-repeat;
  display: inline-block;
  width: 170px;
  height: 79px;
}

#block-block-19 { // Ebay-amazon block
  @include tablet {
    display: none;
  }
 // display: none;

  @include desktop {
    display: inline-block;
    width: 94px;
    height: 50px;
    margin: 0 20px;
  }

}

  #block-menu-block-2 {
    @include desktop {
      width: 40%;
    }
  }
 #block-views-exp-search-page {
   @include desktop {
     width: calc(60% - 134px);
   }
 }

#block-dc-ajax-add-cart-ajax-shopping-cart-teaser {
  display: inline-block;
}
